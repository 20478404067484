import { createSlice } from "@reduxjs/toolkit";

const initialState = { features: [] };

const featuresSlice = createSlice({
  name: "features",
  initialState,
  reducers: {
    addFeatures: (state, action) => {
      const newFeatures = action.payload;
      newFeatures.forEach((feature) => {
        const exists = state.features.some(
          (existingFeature) => JSON.stringify(existingFeature) === JSON.stringify(feature)
        );
        if (!exists) {
          state.features.push(feature);
        }
      });
    },
    removeFeature: (state, action) => {
      state.features = state.features.filter(
        (feature) => JSON.stringify(feature) !== JSON.stringify(action.payload)
      );
    },
  },
});

export const { addFeatures, removeFeature } = featuresSlice.actions;
export default featuresSlice.reducer;