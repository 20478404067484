import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCVdqfECibZi3T99A2w-TZ5uLHEytcEqVY",
    authDomain: "optalpha-905dc.firebaseapp.com",
    projectId: "optalpha-905dc",
    storageBucket: "optalpha-905dc.firebasestorage.app",
    messagingSenderId: "200068609263",
    appId: "1:200068609263:web:9c4194eec3c682090e571a",
    measurementId: "G-6FQDR5MFTM"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };