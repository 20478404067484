// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.condition-list {
  display: flex;
  gap: 5px 15px;
  flex-wrap: wrap;
  align-items: center;
}

.entry-condition-builder {
  background-color: var(--primaryL);
  min-width: 30rem;
  padding: 0.1rem;
  position: relative;
  margin-bottom: 0rem;
}

.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0rem;
}

.condition-label {
  font-size: 0.7rem;
  font-weight: 300;
  margin-bottom: 0.1rem;
}

.head-button {
  background-color: #ffffff;
  color: #f05454;
  border: none;
  border-radius: 4px;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  font-size: 0.7rem;
}

.head-button:hover {
  background-color: #d94343;
}`, "",{"version":3,"sources":["webpack://./src/styles/ConditionBuilder.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".condition-list {\r\n  display: flex;\r\n  gap: 5px 15px;\r\n  flex-wrap: wrap;\r\n  align-items: center;\r\n}\r\n\r\n.entry-condition-builder {\r\n  background-color: var(--primaryL);\r\n  min-width: 30rem;\r\n  padding: 0.1rem;\r\n  position: relative;\r\n  margin-bottom: 0rem;\r\n}\r\n\r\n.header-container {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  margin-bottom: 0rem;\r\n}\r\n\r\n.condition-label {\r\n  font-size: 0.7rem;\r\n  font-weight: 300;\r\n  margin-bottom: 0.1rem;\r\n}\r\n\r\n.head-button {\r\n  background-color: #ffffff;\r\n  color: #f05454;\r\n  border: none;\r\n  border-radius: 4px;\r\n  padding: 0.3rem 0.6rem;\r\n  cursor: pointer;\r\n  font-size: 0.7rem;\r\n}\r\n\r\n.head-button:hover {\r\n  background-color: #d94343;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
