import { IconButton, Label } from './FormCompos';
import React, { useEffect, useRef } from "react";
import ConditionBuilder from "./ConditionBuilder";

const HoldCondition = ({ getdata, resetGetData, holdConditions, setHoldConditions }) => {

    const conditionBuilderRefs = useRef({}); // Store multiple refs dynamically

    useEffect(() => {
        if (getdata) {
            holdConditions.forEach((holdCond) => {
                if (conditionBuilderRefs.current[holdCond.id]) {
                    conditionBuilderRefs.current[holdCond.id].triggerUpdate();
                }
            });

            setTimeout(() => resetGetData(), 500);
        }
    }, [getdata, resetGetData, holdConditions]);

    const addHoldCond = () => {
        setHoldConditions(prev => [
            ...prev,
            { id: Date.now(), conditions: [] }
        ]);
    };

    const deleteHoldCond = (id) => {
        if (holdConditions.length > 0) {
            setHoldConditions(prev => prev.filter(holdCond => holdCond.id !== id));
        }
    };

    const updateHoldCondition = (id, updatedConditions) => {
        setHoldConditions(prev =>
            prev.map(holdCond =>
                holdCond.id === id ? { ...holdCond, conditions: updatedConditions } : holdCond
            )
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "left", gap: "1rem" }}>
            <div style={{ display: "flex", gap: "2rem" }}>
                <h3 style={{ textAlign: "left", fontSize: "1rem", marginBottom: "0rem" }}>Hold Condition</h3>
                <IconButton icon="+" label="" onClick={(e) => { e.preventDefault(); addHoldCond(); }} />
            </div>
            {holdConditions.map((holdCond, index) => (
                <div
                    key={holdCond.id}
                    style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
                    <Label text={`hc${index + 1}`} />

                    <ConditionBuilder
                        ref={(el) => (conditionBuilderRefs.current[holdCond.id] = el)}
                        label={`Hold Condition${index + 1}`}
                        onUpdate={(updatedConditions) => updateHoldCondition(holdCond.id, updatedConditions)}
                        onDelete={() => updateHoldCondition(holdCond.id, [])}
                    />

                    <IconButton icon="+" label="" onClick={(e) => { e.preventDefault(); addHoldCond(); }} />
                    <IconButton icon="-" label="" onClick={(e) => { e.preventDefault(); deleteHoldCond(holdCond.id); }} />
                </div>

            ))}
        </div>
    );
};

export default HoldCondition;
