
import React, { useEffect, useState, useMemo } from "react";
import { conditionCollectionMain } from "./indicators";
import "../../styles/IndicatorDropdown.css";

const IndicatorDropdown = React.memo(({ OcLen, holdCondLen, onUpdate, savedIndicator = null }) => {

    const [readyForDone, setReadyForDone] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedIndicator, setSelectedIndicator] = useState(savedIndicator);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const allIndicators = useMemo(() => conditionCollectionMain.All, []);

    useEffect(() => {
        if (savedIndicator) {
            setSelectedIndicator({
                ...savedIndicator,
                CurrentBackend: savedIndicator.CurrentBackend || {},
                CurrentProperties: savedIndicator.CurrentProperties || {},
            });
        }
    }, [savedIndicator]);

    useEffect(() => {
        if (readyForDone && !isPopupOpen) {
            handleDone();
            setReadyForDone(false);
        }
    }, [readyForDone]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const handleSelect = (indicator) => {
        if (indicator.Id === "hold") {
            const holdCondIdObject = Object.fromEntries(
                Array.from({ length: holdCondLen }, (_, i) => [i + 1, `hc${i + 1}`])
            );
            indicator.Properties.Hold_Key = holdCondIdObject;
        }

        if (indicator.Id === "options") {
            const opobj = Object.fromEntries(
                Array.from({ length: OcLen }, (_, i) => [i + 1, `op${i + 1}_`])
            );
            indicator.Properties.OP_Key = opobj;
        }
        if (!indicator.CurrentProperties || indicator.Id === "hold" || indicator.Id === "options") {
            let condname = indicator.Short;
            let defaultvalues = {};

            let propers = Object.keys(indicator.Properties);
            propers.map((props) => {
                condname = condname.replaceAll(
                    props,
                    Array.isArray(indicator.Properties[props])
                        ? indicator.Properties[props].join(", ")
                        : typeof indicator.Properties[props] === "object" ? indicator.Properties[props][1] : indicator.Properties[props]
                );

                defaultvalues[props] = indicator.Properties[props];
            });
            indicator["CurrentProperties"] = defaultvalues;
            indicator["Name"] = condname;
        }

        setSelectedIndicator(JSON.parse(JSON.stringify(indicator)));
        if (indicator.Properties && Object.keys(indicator.Properties).length > 0) { setIsPopupOpen(true); }
        else { setReadyForDone(true); }
    };

    const handleToggleDropdown = () => {
        if (selectedIndicator) { handleSelect(selectedIndicator); }
    };

    const handlePropertyChange = (key, value, keyType, itemKey) => {
        if (keyType === "input") {
            setSelectedIndicator((prev) => ({
                ...prev,
                CurrentProperties: {
                    ...prev.CurrentProperties,
                    [key]: value
                }
            }));
        }
        else if ((keyType === "AddableArray") && itemKey !== undefined) {
            setSelectedIndicator((prev) => {
                var updatedArray = [...prev.CurrentProperties[key]];
                updatedArray[itemKey] = value;
                return {
                    ...prev,
                    CurrentProperties: {
                        ...prev.CurrentProperties,
                        [key]: updatedArray
                    }
                }
            });
        } else if ((keyType === "dropdown") && itemKey) {
            const newValue = { ...value };
            const InitVal = newValue["1"];
            newValue["1"] = newValue[itemKey];
            newValue[itemKey] = InitVal;
            setSelectedIndicator((prev) => {
                return {
                    ...prev,
                    CurrentProperties: {
                        ...prev.CurrentProperties,
                        [key]: newValue
                    }
                }
            });
        }
    };

    const handleDone = () => {
        selectedIndicator["Name"] = selectedIndicator.Short;
        selectedIndicator["CurrentBackend"] = structuredClone(selectedIndicator.Backend);

        Object.entries(selectedIndicator.CurrentProperties).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                selectedIndicator.Name = selectedIndicator.Name.replaceAll(key, "[" + value.join(", ") + "]");
                if (Object.keys(selectedIndicator.CurrentBackend.Feature).length > 0) {
                    selectedIndicator.CurrentBackend.Feature.feature_name = selectedIndicator.CurrentBackend.Feature.feature_name.replaceAll(key, value.join("_")).replaceAll(":", "_").replaceAll(".", "_");
                    var feature_value = selectedIndicator.CurrentBackend.Feature.feature_value
                    selectedIndicator.CurrentBackend.Feature.feature_value = Array.isArray(feature_value)
                        ? feature_value.map(item => item === key ? value : item)
                        : feature_value === key
                            ? value
                            : feature_value;
                }
                selectedIndicator.CurrentBackend.Usage = selectedIndicator.CurrentBackend.Usage.replaceAll(key, value.join("_")).replaceAll(":", "_").replaceAll(".", "_");
            } else if (typeof value === 'object' && value !== null) {
                selectedIndicator.Name = selectedIndicator.Name.replaceAll(key, value[1]);
                if (Object.keys(selectedIndicator.CurrentBackend.Feature).length > 0) {
                    selectedIndicator.CurrentBackend.Feature.feature_name = selectedIndicator.CurrentBackend.Feature.feature_name.replaceAll(key, value[1]).replaceAll(":", "_").replaceAll(".", "_");
                    var feature_value = selectedIndicator.CurrentBackend.Feature.feature_value
                    selectedIndicator.CurrentBackend.Feature.feature_value = Array.isArray(feature_value)
                        ? feature_value.map(item => item === key ? value[1] : item)
                        : feature_value === key
                            ? value[1]
                            : feature_value;
                }
                selectedIndicator.CurrentBackend.Usage = selectedIndicator.CurrentBackend.Usage.replaceAll(key, value[1]).replaceAll(":", "_").replaceAll(".", "_");
            } else {
                selectedIndicator.Name = selectedIndicator.Name.replaceAll(key, value);
                if (Object.keys(selectedIndicator.CurrentBackend.Feature).length > 0) {
                    selectedIndicator.CurrentBackend.Feature.feature_name = selectedIndicator.CurrentBackend.Feature.feature_name.replaceAll(key, value).replaceAll(":", "_").replaceAll(".", "_");
                    var feature_value = selectedIndicator.CurrentBackend.Feature.feature_value
                    selectedIndicator.CurrentBackend.Feature.feature_value = Array.isArray(feature_value)
                        ? feature_value.map(item => item === key ? value : item)
                        : feature_value === key
                            ? value
                            : feature_value;
                }
                selectedIndicator.CurrentBackend.Usage = selectedIndicator.CurrentBackend.Usage.replaceAll(key, value).replaceAll(":", "_").replaceAll(".", "_");
            }
        });
        selectedIndicator.Id = selectedIndicator.CurrentBackend.Usage + Date.now();

        onUpdate(selectedIndicator);
        setIsPopupOpen(false);
    };

    const handleAddItem = (key) => {
        setSelectedIndicator(prev => ({
            ...prev,
            CurrentProperties: {
                ...prev.CurrentProperties,
                [key]: [...(prev.CurrentProperties[key] || []), ""]
            }
        }));
    };

    const handleRemoveItem = (key, index) => {
        setSelectedIndicator(prev => ({
            ...prev,
            CurrentProperties: {
                ...prev.CurrentProperties,
                [key]: prev.CurrentProperties[key].filter((_, i) => i !== index)
            }
        }));
    };

    const handleDelete = () => {
        setSelectedIndicator(null);
        setIsPopupOpen(false);
    };

    const filteredIndicators = searchTerm.trim() === ""
        ? allIndicators
        : allIndicators.filter((indicator) =>
            indicator.Full.toLowerCase().includes(searchTerm.trim()) ||
            indicator.Short.toLowerCase().includes(searchTerm.trim())
        );

    return (
        <div className="dropdown">
            <button type="button" className="dropdown-button" onClick={() => handleToggleDropdown()}>
                {selectedIndicator
                    ? selectedIndicator.Name
                    : (
                        <span className="add-text">+ ADD</span>
                    )}
            </button>
            <div className="dropdown-content">
                <input
                    type="text"
                    placeholder="Search ..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="search-input"
                />
                <div className="grid-container">
                    {filteredIndicators.length > 0 ? (
                        filteredIndicators.map((indicator) => (
                            <div
                                key={indicator.Short}
                                className="grid-item"
                                onClick={() => handleSelect(indicator)}
                            >
                                {indicator.Short}
                            </div>
                        ))
                    ) : (
                        <div className="no-results">No Results Found</div>
                    )}
                </div>

            </div>

            {/* Popup Window */}
            {isPopupOpen && (
                <div className="popup-overlay">
                    <div className="popup">
                        <h3>Set {selectedIndicator.Full}</h3>
                        <div className="properties-container">
                            {Object.entries(selectedIndicator.CurrentProperties).map(([key, value]) => (
                                <div key={key} className="property-item">
                                    <label>{key}</label>
                                    {Array.isArray(value) ? (
                                        <div>
                                            {value.map((itemValue, index) => (
                                                <div key={index} style={{ display: "flex", alignItems: "center" }}>
                                                    <input
                                                        type="text"
                                                        value={itemValue || ""}
                                                        onChange={(e) => handlePropertyChange(key, e.target.value, "AddableArray", index)}
                                                    />
                                                    <button type="button" onClick={() => handleRemoveItem(key, index)}>-</button>
                                                </div>
                                            ))}
                                            <button type="button" onClick={() => handleAddItem(key)}>
                                                + Add New Item
                                            </button>
                                        </div>
                                    ) : (typeof value === 'object' && value !== null) ? (
                                        <select onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            const selectedKey = Object.keys(value).find(k => value[k] === selectedValue);
                                            handlePropertyChange(key, value, "dropdown", selectedKey);
                                        }} value={value.selected || ""}>
                                            {Object.values(value).map((option, idx) => (
                                                <option key={idx} value={option}>{option}</option>
                                            ))}
                                        </select>)
                                        : (
                                            <input
                                                type="text" value={value} onChange={(e) => handlePropertyChange(key, e.target.value, "input")} />
                                        )}
                                </div>
                            ))}
                        </div>

                        <div className="popup-buttons">
                            <button className="btn-done" onClick={handleDone}>
                                Save
                            </button>
                            <button className="btn-delete" onClick={handleDelete}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});

export default IndicatorDropdown;
