import React, { useState, useEffect } from "react";
import StrategyUI from "./strategyUI";
import Strategy from "./strategyClass";
import "../../styles/Template.css";
import { updateDoc, doc, setDoc, getDoc, deleteField } from "firebase/firestore";
import { auth, db } from "../../firebaseConfig";
const LOCAL_STORAGE_KEY = "current_strategy";

const Template = () => {
  const [templateJSON, setTemplateJSON] = useState({});
  const [strategyNames, setStrategyNames] = useState([]);
  const [loading, setLoading] = useState(false);
  var [currentStrategyName, setCurrentStrategyName] = useState('Strategy Name');
  const [showPopup, setShowPopup] = useState(false);
  const [notification, setNotification] = useState(null);

  const loadStoredStrategy = () => {
    const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedData) {
      return Object.assign(new Strategy(), JSON.parse(storedData)); // Restore class instance
    }
    return new Strategy();
  };

  const [StrategyData, setStrategyData] = useState(loadStoredStrategy);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(StrategyData));
  }, [StrategyData]);

  useEffect(() => {
    if (showPopup) {
      document.getElementById("sNameTB").value = currentStrategyName
    }
  }, [showPopup]);

  useEffect(() => {
    loadStrategiesFromFirebase();
  }, []);

  const loadStrategiesFromFirebase = async () => {
    setLoading(true);
    const user = auth.currentUser;
    if (!user) {
      showNotification("User not logged in!");
      return;
    }

    const userRef = doc(db, "userData", user.email);

    try {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const strategies = userDoc.data().modelDetails || {};
        setStrategyNames(Object.keys(strategies));
      }
    } catch (error) {
      showNotification("Error fetching strategies!");
    }
    setLoading(false);
  };

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(null), 3000); // Auto-hide after 3 seconds
  };

  const saveToFirebase = async (strategyName) => {
    setLoading(true);
    const user = auth.currentUser;
    if (!user) {
      showNotification("User not logged in!");
      return;
    }

    const userRef = doc(db, "userData", user.email);

    try {
      // Fetch existing user data
      const userDoc = await getDoc(userRef);
      let existingData = userDoc.exists() ? userDoc.data() : { modelDetails: {} };

      // Merge new strategy without overwriting other keys
      existingData.modelDetails = {
        ...existingData.modelDetails,
        [strategyName]: JSON.stringify(StrategyData),
      };

      // Save back to Firestore
      await setDoc(userRef, existingData, { merge: true });

      showNotification("Strategy saved successfully!");
      loadStrategiesFromFirebase(); // Refresh strategy list
    } catch (error) {
      showNotification("Failed to save strategy!");
    }
    setLoading(false);
  };

  const openStrategy = async (strategyName) => {
    setLoading(true);
    const user = auth.currentUser;
    if (!user) {
      showNotification("User not logged in!");
      return;
    }

    const userRef = doc(db, "userData", user.email);

    try {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const strategies = userDoc.data().modelDetails || {};
        if (strategies[strategyName]) {
          const strategy = JSON.parse(strategies[strategyName]);
          localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(strategy));
          setStrategyData(strategy);

          showNotification(`Loaded strategy: ${strategyName}`);
        } else {
          showNotification("Strategy not found!");
        }
      }
    } catch (error) {
      showNotification("Error opening strategy!");
    }
    setLoading(false);
    setShowPopup(false);
  };

  const createNewStrategy = () => {
    const newStrategy = new Strategy();
    setCurrentStrategyName(document.getElementById("sNameTB")?.value.trim());
    setStrategyData(newStrategy);
  };

  const deleteStrategy = async (strategyName) => {
    if (!strategyName) {
      showNotification("Please enter a valid strategy name!");
      return;
    }

    setLoading(true);
    const user = auth.currentUser;
    if (!user) {
      showNotification("User not logged in!");
      setLoading(false);
      return;
    }

    const userRef = doc(db, "userData", user.email);

    try {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        let existingData = userDoc.data().modelDetails || {};

        if (!existingData[strategyName]) {
          showNotification("Strategy not found!");
          setLoading(false);
          return;
        }

        await updateDoc(userRef, {
          [`modelDetails.${strategyName}`]: deleteField()
        });

        loadStrategiesFromFirebase(); // Refresh strategy list

        document.getElementById("sNameTB").value = "";
        if (currentStrategyName === strategyName) {
          setCurrentStrategyName("");
          setStrategyData(new Strategy());
        }
      }
    } catch (error) {
      showNotification("Failed to delete strategy!");
    }
    setLoading(false);
  };

  const extractFeatures = (obj, features = new Set()) => {
    if (typeof obj !== 'object' || obj === null) { return; }
    if (Array.isArray(obj)) {
      obj.forEach(item => extractFeatures(item, features));
    } else {
      Object.keys(obj).forEach(key => {
        if (key === 'Feature' && Object.keys(obj[key]).length > 0) {
          let value = obj[key];
          const convertNumbers = (item) => {
            if (Array.isArray(item)) {
              return item.map(convertNumbers);
            }
            if (typeof item === "object" && item !== null) {
              return Object.fromEntries(
                Object.entries(item).map(([key, value]) => [key, convertNumbers(value)])
              );
            }
            if (typeof item === "string") {
              if (!isNaN(item) && item.trim() !== "") {
                return item.includes(".") ? parseFloat(item) : parseInt(item, 10);
              }
            }
            return item;
          };
          features.add(JSON.stringify(convertNumbers(value)));
        } else {
          extractFeatures(obj[key], features);
        }
      });
    }

    return features;
  };
  const fillUsageArray = (conditions = []) => {
    const usageArray = conditions
      .filter(condition => condition.element && condition.element.Usage) // Ensure Usage exists
      .map(condition => condition.element.Usage);

    const mergedArray = [];
    for (let i = 0; i < usageArray.length; i++) {
      if (typeof usageArray[i] === "string" && usageArray[i].endsWith(";") && i + 1 < usageArray.length) {
        mergedArray.push(usageArray[i] + usageArray[i + 1]); // Concatenate with next element
        i++; // Skip next element as it is already merged
      } else if (typeof usageArray[i] === "string") {
        mergedArray.push(usageArray[i]);
      }
    }

    return mergedArray;
  };
  const transformExpiries = (contract) => {
    const expiryMap = {};
    contract.forEach(({ expiry }) => {
      if (!expiryMap[expiry]) {
        const isMonthly = expiry.includes("Month_");
        const expIndex = parseInt(expiry.split("_")[1], 10) || 0;
        expiryMap[expiry] = {
          exp_name: expiry,
          exp_skip: expIndex,
          monthly: isMonthly,
        };
      }
    });
    return Object.values(expiryMap);
  };
  const stoplossTargetToPosNeg = (exitPriceChange) => {
    const { stoploss, target, name: originalName } = exitPriceChange
    const name = originalName === "none" ? "" : originalName;
    const transactionType = name === "under"
      ? StrategyData.transaction_type
      : StrategyData.option_contracts.find((_, index) => `op${index + 1}_` === name)?.transaction_type;

    return transactionType
      ? {
        name,
        neg: transactionType === "SELL" ? target : stoploss,
        pos: transactionType === "SELL" ? stoploss : target
      }
      : { name, neg: "", pos: "" };
  };
  const convertStrategyData = (data) => {
    if (Array.isArray(data)) {
      return data.map(convertStrategyData);
    } else if (typeof data === "object" && data !== null) {
      let newData = { ...data };
      if (newData.element && newData.element.CurrentBackend) {
        newData.element = newData.element.CurrentBackend;// keeps only current backend
      }
      for (let key in newData) {
        newData[key] = convertStrategyData(newData[key]);
      }
      return newData;
    }
    return data;
  };
  const restructureJson = (StrategyData) => {

    var upStrategyData = convertStrategyData(JSON.parse(JSON.stringify(StrategyData)));

    const option_contracts = upStrategyData.option_contracts.map((contract, index) => ({ contract_name: `op${index + 1}_`, ...contract, }));
    option_contracts.forEach((contract, i) => {
      if (Array.isArray(contract.customized_contract_condition)) { option_contracts[i].contract_type = fillUsageArray(contract.customized_contract_condition); }
      delete contract.customized_contract_condition;
    });
    const exps = transformExpiries(upStrategyData.option_contracts);

    const features = Array.from(extractFeatures(upStrategyData)).map(item => JSON.parse(item));
    const hold_conds1 = {};
    upStrategyData.hold_conds.forEach((condition, index) => {
      hold_conds1[`hc${index + 1}`] = fillUsageArray(condition.conditions);
    });
    const ent_cond = fillUsageArray(upStrategyData.ent_cond)
    const ext_ent_cond_comb = upStrategyData.ext_ent_cond_comb === "and" ? "&" : upStrategyData.ext_ent_cond_comb === "or" ? "|" : upStrategyData.ext_ent_cond_comb === "none" ? "" : upStrategyData.ext_ent_cond_comb;
    const ext_ent_cond = fillUsageArray(upStrategyData.ext_ent_cond);
    const partial_exits = upStrategyData.partial_exits.map(exit => {
      return {
        ...exit,
        partial_exit_price_change: stoplossTargetToPosNeg(exit.partial_exit_price_change) || {},
        partial_ext_cond: fillUsageArray(exit.partial_ext_cond),
        exit_price_change: stoplossTargetToPosNeg(exit.exit_price_change) || {},
        ext_cond: fillUsageArray(exit.ext_cond)
      };
    });
    const exit_price_change = stoplossTargetToPosNeg(upStrategyData.exit_price_change) || {};
    const ext_cond = fillUsageArray(upStrategyData.ext_cond)
    return { features, exps, ...upStrategyData, option_contracts, hold_conds: hold_conds1, ent_cond, ext_ent_cond_comb, ext_ent_cond, partial_exits, exit_price_change, ext_cond };
  };
  const getTemplate = async () => {
    const result = restructureJson(StrategyData);
    setTemplateJSON(result);
  };

  return (
    <div>
      {notification && (
        <div className="toast">
          {notification}
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
        <button type="button" onClick={() => setShowPopup(true)} disabled={loading}
          className="link-button" style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
          {currentStrategyName}
        </button>
      </div>
      <StrategyUI key={JSON.stringify(StrategyData)} StrategyData={StrategyData} setStrategyData={setStrategyData} />
      <div className="button-section">
        <button type="button" onClick={() => getTemplate()}>Get Template</button>
      </div>
      <div className="InstrumentCard">
        <h3>Template</h3> <pre>{JSON.stringify(templateJSON, null, 2)}</pre>
        {/* <h3>StrategyData</h3> <pre>{JSON.stringify(StrategyData, null, 2)}</pre> */}
      </div>

      {showPopup &&
        <div className="popup-overlay">
          <div className="popup">
            <input type="text" id="sNameTB" placeholder={document.getElementById("sNameTB")?.value.trim()}
              onChange={(e) => { document.getElementById("sNameTB").value = e.target.value }} />
            <div className="button-section">

              <button type="button" onClick={() => {
                createNewStrategy();
                setShowPopup(false);
              }} disabled={loading} >       Create New Strategy         </button>

            </div>
            <div className="button-section">
              <button type="button" onClick={() => { saveToFirebase(document.getElementById("sNameTB")?.value.trim()) }} disabled={loading}>  {loading ? "Loading..." : "Save"}</button>
              <button type="button" onClick={() => { openStrategy(document.getElementById("sNameTB")?.value.trim()) }} disabled={loading} >  {loading ? "Loading..." : "Open"}   </button>
              <button type="button" onClick={() => { deleteStrategy(document.getElementById("sNameTB")?.value.trim()) }} disabled={loading} > {loading ? "Loading..." : "Delete"}    </button>
              <button type="button" onClick={() => { setShowPopup(false); }}  >      Cancel   </button>
            </div>
            <p style={{ marginTop: "5rem" }}>Existing Strategies</p>
            <ul>
              {strategyNames.length > 0 ? (
                strategyNames.map((name) => (
                  <li key={name}>
                    <button type="button" onClick={() => { setCurrentStrategyName(name); document.getElementById("sNameTB").value = name }} disabled={loading}
                      className={`link-button ${document.getElementById("sNameTB")?.value === name ? "selected" : ""}`} >
                      {name}
                    </button>
                  </li>
                ))
              ) : (
                <p>No saved strategies found.</p>
              )}
            </ul>

          </div>
        </div>

      }


    </div>

  );
};

export default Template;