import React, { useState, useEffect } from "react";
import OptionContract from "./OptionContract";
import { TextInput, Dropdown } from "./FormCompos";
import EntryCondition from "./EntryCondition";
import HoldCondition from "./HoldCondition";
import ExitCondition from "./ExitCondition";

import PartialExitCond from "./PartialExitCond";
import "../../styles/Template.css";

const Template = () => {
  const [templateJSON, setTemplateJSON] = useState({});
  const [combinedData, setcombinedData] = useState([])
  const [getdata, setGetdata] = useState(false);
  const [formData, setFormData] = useState({ name: "BANKNIFTY", transaction_type: "BUY", time_frame_minutes: "1", ent_lots: 1, });
  const [contracts, setContracts] = useState([]);
  const [EntryCondData, setentryCondData] = useState({ ent_cond: "", ext_ent_cond_comb: "none", ext_ent_cond: "" });
  const [partialExitCond, setPartialExitCond] = useState([]);
  const [holdConditions, setHoldConditions] = useState([]);
  const [ExitCondData, setexitCondData] = useState({ "exit_price_change": { "name": "under", "pos": "", "neg": "", "stoploss": "", "target": "" }, "ext_cond": [] });
  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (getdata) {
      const ucombinedData = {
        ...formData,
        option_contracts: contracts.map((contract, index) => ({ contract_name: `op${index + 1}_`, ...contract, })),
        "hold_conds": holdConditions,
        ...EntryCondData,
        ...ExitCondData,
      };
      setcombinedData(ucombinedData);
    }
  }, [getdata, formData, contracts, EntryCondData, holdConditions, ExitCondData]);

  useEffect(() => {
    if (getdata && combinedData) {
      getTemplate();
      setGetdata(false);
    }
  }, [combinedData]);

  const extractFeatures = (obj, features = new Set()) => {
    if (typeof obj !== 'object' || obj === null) {
      return;
    }

    if (Array.isArray(obj)) {
      obj.forEach(item => extractFeatures(item, features));
    } else {
      Object.keys(obj).forEach(key => {
        if (key === 'Feature' && obj[key] !== 'null') {
          features.add(JSON.stringify(obj[key]));
        } else {
          extractFeatures(obj[key], features);
        }
      });
    }
    return features;
  };

  const fillUsageArray = (conditions = []) =>
    conditions.filter(condition => condition.element).map(condition => condition.element.Usage);

  const transformExpiries = (contract) => {
    const expiryMap = {};

    contract.forEach(({ expiry }) => {
      if (!expiryMap[expiry]) {
        const isMonthly = expiry.includes("Month_");
        const expIndex = parseInt(expiry.split("_")[1], 10) || 0;
        expiryMap[expiry] = {
          exp_name: expiry,
          exp_skip: expIndex,
          monthly: isMonthly,
        };
      }
    });

    return Object.values(expiryMap);
  };

  const stoplossTargetToPosNeg = (exitPriceChange) => {
    const { stoploss, target, name } = exitPriceChange
    const transactionType = name === "under"
      ? formData.transaction_type
      : contracts.find((_, index) => `op${index + 1}_` === name)?.transaction_type;

    return transactionType
      ? {
        ...exitPriceChange,
        neg: transactionType === "SELL" ? target : stoploss,
        pos: transactionType === "SELL" ? stoploss : target
      }
      : exitPriceChange;
  };
  const restructureJson = (combinedData) => {
    const features = Array.from(extractFeatures(combinedData)).map(item => JSON.parse(item));
    const ent_cond = fillUsageArray(combinedData.ent_cond)
    const ext_ent_cond = fillUsageArray(combinedData.ext_ent_cond)

    const ext_cond = fillUsageArray(combinedData.ext_cond)
    const { stoploss, target, ...filteredExitPriceChange } = stoplossTargetToPosNeg(combinedData.exit_price_change) || {};


    const partial_exits = combinedData.partial_exits.map(exit => {
      const { stoploss, target, ...filteredPartialExitPriceChange } =
        stoplossTargetToPosNeg(exit.partial_exit_price_change) || {};

      const { stoploss: sl, target: tg, ...filteredExitPriceChange } =
        stoplossTargetToPosNeg(exit.exit_price_change) || {};

      return {
        ...exit,
        partial_exit_price_change: filteredPartialExitPriceChange,
        partial_ext_cond: fillUsageArray(exit.partial_ext_cond),
        exit_price_change: filteredExitPriceChange,
        ext_cond: fillUsageArray(exit.ext_cond)
      };
    });

    const ext_ent_cond_comb = combinedData.ext_ent_cond_comb === "and" ? "&" :
      combinedData.ext_ent_cond_comb === "or" ? "|" :
        combinedData.ext_ent_cond_comb === "none" ? "" : combinedData.ext_ent_cond_comb;

    const hold_conds1 = {};
    combinedData.hold_conds.forEach((condition, index) => {
      hold_conds1[`hc${index + 1}`] = fillUsageArray(condition.conditions);
    });

    const option_contracts = contracts.map((contract, index) => ({ contract_name: `op${index + 1}_`, ...contract, }));
    const exps = transformExpiries(option_contracts);
    return {
      ...formData, exps,
      option_contracts,
      features, hold_conds: hold_conds1, ent_cond, ext_ent_cond_comb, ext_ent_cond, partial_exits, exit_price_change: filteredExitPriceChange, ext_cond
    };
  };

  const getTemplate = async () => {
    const combinedData = {
      ...formData,
      option_contracts: contracts.map((contract, index) => ({ contract_name: `op${index + 1}_`, ...contract, })),
      "hold_conds": holdConditions,
      ...EntryCondData,
      "partial_exits": partialExitCond,
      ...ExitCondData,
    };
    setcombinedData(combinedData);
    const result = restructureJson(combinedData);
    setTemplateJSON(result);
  };

  return (
    <form className="TemplateContainer">
      <h2 >Template Generator</h2>
      <div className="TemplateCard">
        <div className="InstrumentCard"  >
          <TextInput
            label="Instrument Name"
            value={formData.name}
            onChange={(value) => handleChange("name", value)}
            placeholder="instrument name"
          />
          <Dropdown
            label="Entry Type"
            options={["BUY", "SELL"]}
            value={formData.transaction_type}
            onChange={(value) => handleChange("transaction_type", value)}
          />
          <Dropdown
            label="Candle Interval(min)"
            options={Array.from({ length: 375 }, (_, i) => (i + 1).toString())}
            value={formData.time_frame_minutes}
            onChange={(value) => handleChange("time_frame_minutes", Number(value))}
          />
          <TextInput
            label="# Entry Lots"
            value={formData.ent_lots}
            onChange={(value) => handleChange("ent_lots", value)}
            placeholder="instrument name"
          />
        </div>
      </div>
      <div class="TemplateCard"> <OptionContract contracts={contracts} setContracts={setContracts} /> </div>
      <div class="TemplateCard"> <HoldCondition getdata={getdata} resetGetData={() => setGetdata(false)} holdConditions={holdConditions} setHoldConditions={setHoldConditions} />  </div>
      <div class="TemplateCard"> <EntryCondition getdata={getdata} resetGetData={() => setGetdata(false)} EntryCondData={EntryCondData} setentryCondData={setentryCondData} />  </div>
      <div class="TemplateCard"> <PartialExitCond getdata={getdata} resetGetData={() => setGetdata(false)} contracts={contracts} formData={formData} partialExitCond={partialExitCond} setPartialExitCond={setPartialExitCond} />  </div>
      <div class="TemplateCard"> <h5 style={{ align: "left", fontSize: "1rem" }}>Exit Condition</h5> <ExitCondition label="Exit Condition" getdata={getdata} resetGetData={() => setGetdata(false)} contracts={contracts} ExitCondData={ExitCondData} setexitCondData={setexitCondData} />  </div>

      <div className="button-section">
        <button type="button" onClick={() => setGetdata(true)}>Get Template</button>
      </div>
      <div >
        <h3>Template</h3> <pre>{JSON.stringify(templateJSON, null, 2)}</pre>
      </div>
    </form>
  );
};

export default Template;