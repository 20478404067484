
import React, { useState } from "react";
import IndicatorDropdown from "./IndicatorDropdown";
import "../../styles/ConditionBuilder.css"
import { useImperativeHandle, forwardRef } from "react";

const ConditionBuilder = forwardRef(({ label = "", onUpdate, onDelete }, ref) => {
    useImperativeHandle(ref, () => ({
        triggerUpdate: () => {
            onUpdate(localConditions);
        },
    }));
    const [localConditions, setLocalConditions] = useState([{ id: Date.now(), element: null },]);

    const handleUpdateDropdown = (index, updatedDropdown) => {
        const updatedConditions = [...localConditions];
        updatedConditions[index] = {
            ...updatedConditions[index],
            element: updatedDropdown,
        };
        if (updatedConditions.length === 0 || updatedConditions[updatedConditions.length - 1].element !== null) {
            updatedConditions.push({ id: Date.now(), element: null });
        }
        setLocalConditions(updatedConditions);
    };

    const handleRemoveDropdown = (index) => {
        if (localConditions.length > 1) {
            const updatedConditions = localConditions.filter((_, i) => i !== index);
            setLocalConditions(updatedConditions);
        }
    };

    const handleReset = () => {
        if (localConditions.length === 1 && !localConditions[0].element) {
            return;
        }
        onDelete();
        setLocalConditions([{ id: Date.now(), element: null }]);
    };

    return (
        <div
            className="entry-condition-builder">
            <div className="header-container">
                <label className="condition-label">
                    {label}
                </label>
                <div>
                    <button className="head-button" onClick={(e) => { e.preventDefault(); handleReset(); }}>
                        Reset
                    </button></div>
            </div>

            <div className="condition-list">
                {localConditions.map((condition, index) => (
                    <div className="condition-item" key={condition.id}>
                        <IndicatorDropdown
                            onUpdate={(updatedDropdown) =>
                                handleUpdateDropdown(index, updatedDropdown)
                            }
                            onDelete={() => handleRemoveDropdown(index)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
});

export default ConditionBuilder;