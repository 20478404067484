import { IconButton, Label } from './FormCompos';
import React, { } from "react";
import ConditionBuilder from "./ConditionBuilder";

const HoldCondition = ({ OcLen, holdConditions = [], setHoldConditions }) => {

    const addHoldCond = () => {
        setHoldConditions([
            ...holdConditions,
            { id: Date.now(), conditions: [{ id: Date.now(), element: null },] }
        ]);
    };

    const deleteHoldCond = (id) => {
        if (holdConditions.length > 0) {
            setHoldConditions(holdConditions.filter(holdCond => holdCond.id !== id));
        }
    };

    const updateHoldCondition = (id, updatedConditions) => {
        setHoldConditions(
            holdConditions.map(holdCond =>
                holdCond.id === id ? { ...holdCond, conditions: updatedConditions } : holdCond
            )
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "left", gap: "1rem" }}>
            <div style={{ display: "flex", gap: "2rem" }}>
                <h3 style={{ textAlign: "left", fontSize: "1rem", marginBottom: "0rem" }}>Hold Condition</h3>
                <IconButton icon="+" label="" onClick={(e) => { e.preventDefault(); addHoldCond(); }} />
            </div>
            {holdConditions.map((holdCond, index) => (
                <div
                    key={holdCond.id}
                    style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
                    <Label text={`hc${index + 1}`} />

                    <ConditionBuilder
                        OcLen={OcLen}
                        label={`Hold Condition${index + 1}`}
                        localConditions={holdCond.conditions}
                        setLocalConditions={(newConditions) => updateHoldCondition(holdCond.id, newConditions)}
                    />

                    <IconButton icon="+" label="" onClick={(e) => { e.preventDefault(); addHoldCond(); }} />
                    <IconButton icon="-" label="" onClick={(e) => { e.preventDefault(); deleteHoldCond(holdCond.id); }} />
                </div>

            ))}
        </div>
    );
};

export default HoldCondition;