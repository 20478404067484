import { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { Info } from "lucide-react";
// TextInput Component

export const TextInput = ({ label, value, onChange, placeholder = "", fieldInfo }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div style={{ marginBottom: "1rem", position: "relative" }}>
      <label style={{ display: "block", fontSize: "0.7rem", fontWeight: "300", marginBottom: "0.1rem" }}>
        {label}
        {fieldInfo && (
          <span
            style={{ display: "inline-block", marginLeft: "0.3rem", position: "relative" }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <Info style={{ width: "0.95rem", height: "0.7rem", cursor: "pointer", color: "var(--primaryD)" }} />
            {showTooltip && (
              <div style={{
                position: "absolute",
                bottom: "120%",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "var(--successL)",
                color: "Black",
                padding: "0.4rem 0.6rem",
                borderRadius: "4px",
                fontSize: ".8rem",
                whiteSpace: "nowrap",
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                zIndex: 10
              }}>
                {fieldInfo.split("/n").map((line, index) => (
                  <span key={index}>
                    {line.split("**").map((part, i) =>
                      i % 2 === 1 ? <strong key={i}>{part}</strong> : part
                    )}
                    <br />
                  </span>
                ))}
              </div>
            )}
          </span>
        )}
      </label>
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        style={{
          width: "auto",
          maxWidth: "6rem",
          fontWeight: "500",
          padding: "0.5rem",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      />
    </div>
  );
};

// Dropdown Component
export const Dropdown = ({ label = "", options, value, onChange, fieldInfo }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div style={{ marginBottom: ".1rem" }}>
      <label style={{ display: "block", fontSize: "0.7rem", fontWeight: "300", marginBottom: "0.1rem" }}>
        {label} {fieldInfo && (
          <span
            style={{ display: "inline-block", marginLeft: "0.3rem", position: "relative" }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <Info style={{ width: ".95rem", height: "0.7rem", cursor: "pointer", color: "var(--primaryD)" }} />
            {showTooltip && (
              <div style={{
                position: "absolute",
                bottom: "120%",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "var(--successL)",
                color: "Black",
                padding: "0.4rem 0.6rem",
                borderRadius: "4px",
                fontSize: ".8rem",
                whiteSpace: "nowrap",
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                zIndex: 10
              }}> {fieldInfo.split("/n").map((line, index) => (
                <span key={index}>
                  {line.split("**").map((part, i) =>
                    i % 2 === 1 ? <strong key={i}>{part}</strong> : part
                  )}
                  <br />
                </span>
              ))}
              </div>
            )}
          </span>
        )} </label>
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onClick={(e) => onChange(e.target.value)}
        style={{
          width: "auto",
          padding: "0.5rem",
          fontWeight: "500",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      >

        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>);
};

// IconButton Component
export const IconButton = ({ icon, label, onClick }) => {
  const renderIcon = () => {
    if (icon === "-") return <FaTrash />;
    return icon;
  };

  return (
    <button
      onClick={onClick}
      aria-label={label}
      style={{
        color: "#0000FF",
        margin: "0.9rem 0rem",
        padding: "0.5rem 1rem",
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        fontWeight: "600",
        cursor: "pointer",
        border: "1px solid #ccc",
        borderRadius: "4px",
        backgroundColor: "#f5f5f5",
      }}
    >
      {renderIcon()} {label}
    </button>
  );
}

// Label Component
export const Label = ({ text }) => (
  <label
    style={{
      color: "Blue",
      fontWeight: "700",
      marginTop: "1.2rem",

    }}
  >
    {text}
  </label>
);