import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import Users from './components/Users';
import Navbar from './components/Navbar';
import Models from "./components/Models";
import HistoricalTrades from './components/HistoricalTrades';
import LiveTrades from "./components/LiveTrades";
import TickerData from "./components/TickerData";
import Backtest from "./components/Backtest";
import Logs from "./components/logs";
import Template from "./components/Template/Template";
import { signOut } from "firebase/auth";
import { auth } from "./firebaseConfig";

import './styles/global.css';
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [isAdmin, setIsAdmin] = useState(false);

  const handleLogin = (isAdmin) => {
    setIsAuthenticated(true);
    setIsAdmin(isAdmin);
  };

  const handleLogout = async () => {
    await signOut(auth);
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setIsAdmin(false);
  };

  return (
    <Router>
      {isAuthenticated ? (
        <>
          <Navbar isAdmin={isAdmin} onLogout={handleLogout} />
          <Routes>
            <Route path="/" element={<Navigate to="/backtest" replace />} />

            {isAdmin && (
              <>
                <Route path="/users" element={<Users />} />
                <Route path="/models" element={<Models />} />
                <Route path="/historicalTrades" element={<HistoricalTrades />} />
                <Route path="/liveTrades" element={<LiveTrades />} />
                <Route path="/tickerData" element={<TickerData />} />
                <Route path="/logs" element={<Logs />} />
              </>
            )}

            <Route path="/template" element={<Template />} />
            <Route path="/backtest" element={<Backtest />} />
          </Routes>
        </>
      ) : (
        <LoginForm onLogin={handleLogin} />
      )}
    </Router>
  );
};

export default App;