import React, { useState } from 'react';
import '../styles/LoginForm.css';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebaseConfig";
import api from '../utils/axiosConfig';
import { doc, getDoc } from "firebase/firestore";
import { Eye, EyeOff } from "lucide-react"; // Eye icons
import { useDispatch } from "react-redux";
import { setEmail } from "../redux/emailSlice";

const LoginForm = ({ onLogin }) => {
  const [email, setEmaillocal] = useState("");
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleLogin = async () => {
    dispatch(setEmail(email));
    try {
      const response = await api.post('/login', { username: "optalpha", password: "Rpv@1234" });
      localStorage.setItem('token', response.data.token);
    } catch (err) {
      setError(JSON.stringify(err));
    }
  };

  const login = async (auth, email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const doc_ref = doc(db, "userData", email);
      const userDoc = await getDoc(doc_ref);
      onLogin(userDoc.data().isAdmin);
      setLoading(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    if (!email || !password) {
      setError("Email and password are required.");
      setLoading(false);
      return;
    }
    try {
      await handleLogin();
      await login(auth, email, password);
    } catch (err) {
      setError(err.message || "Login failed.");
    } finally {
      setLoading(false);
    }
  };

  return (

    <div className="login-container">
      <h2>Admin Login</h2>

      <div>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmaillocal(e.target.value)}
          required
        />

        <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ paddingRight: "40px", width: "100%" }}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            style={{
              position: "absolute",
              right: "0px",
              background: "transparent",
              border: "none",
              cursor: "pointer",
            }}
          >
            {showPassword ? <EyeOff size={20} color="gray" /> : <Eye size={20} color="gray" />}
          </button>
        </div>
        <button type="button" disabled={loading} onClick={() => { handleSubmit(); }}>{loading ? "Loging...." : "Login"}</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    </div>
  );
};

export default LoginForm;