const Indicators = [
  {
    Id: "prv_cand",
    Description: "",
    Type: "Indicators",
    Short: "CandleValue Prev_Candle(Shifts)",
    Full: "Previous Candle",
    Backend: {
      Feature: ["prv_cand_Shifts", "prv_cand", "Shifts"],
      Usage: "CandleValue_prv_cand_Shifts",
    },
    Properties: {
      CandleValue: ["open", "high", "low", "close"],
      Shifts: 1
    },
  },
  {
    Id: "cand",
    Description: "",
    Type: "Indicators",
    Short: "CandleValue Candle(Timeframe)",
    Full: "Day Start Candle In MIN",
    Backend: {
      Feature: ["cand_Timeframe", "cand", "Timeframe"],
      Usage: "CandleValue_cand_Timeframe",
    },
    Properties: {
      CandleValue: ["high", "low", "close"],
      Timeframe: 15,
    },
  },
  {
    Id: "pivot",
    Description: "",
    Type: "Indicators",
    Short: "PIVOTV Pivot",
    Full: "Support Resistances Pivot",
    Backend: {
      Feature: ["pivot", "pivot", ""],
      Usage: "PIVOTV_pivot",
    },
    Properties: {
      PIVOTV: ["r1", "r2", "r3", "s1", "s2", "s3", "p"],
    },
  },
  {
    Id: "resample",
    Description: "",
    Type: "Indicators",
    Short: "CandleValue Resample(Timeframe)",
    Full: "Multi Timeframe Resample In MIN",
    Backend: {
      Feature: ["resample_Timeframe", "resample", "Timeframe"],
      Usage: "CandleValue_resample_Timeframe",
    },
    Properties: {
      CandleValue: ["open", "high", "low", "close"],
      Timeframe: "1",
    },
  },
  {
    Id: "stochrsi",
    Description: "",
    Type: "Indicators",
    Short: "StochRSI(Period)",
    Full: "StochRSI",
    Backend: {
      Feature: ["stochrsi_Period", "stochrsi", "Period"],
      Usage: "stochrsi_Period",
    },
    Properties: { Period: "14" },
  },
  {
    Id: "williams_r",
    Description: "",
    Type: "Indicators",
    Short: "WilliamsR(Lbp)",
    Full: "Williams %R",
    Backend: {
      Feature: ["williams_r_Lbp", "williams_r", "Lbp"],
      Usage: "williams_r_Lbp",
    },
    Properties: { Lbp: "14" },
  },
  {
    Id: "donchian_channel_pband",
    Description: "",
    Type: "Indicators",
    Short: "Donchian(Period)",
    Full: "Donchian Channel Pband",
    Backend: {
      Feature: [
        "donchian_channel_pband_Period",
        "donchian_channel_pband",
        "Period",
      ],
      Usage: "donchian_channel_pband_Period",
    },
    Properties: { Period: "14" },
  },
  {
    Id: "VolumeWeightedAveragePrice",
    Description: "",
    Type: "Indicators",
    Short: "VWAP(Period)",
    Full: "Volume Waighted Avg Price",
    Backend: {
      Feature: [
        "VolumeWeightedAveragePrice_Period",
        "VolumeWeightedAveragePrice",
        "Period",
      ],
      Usage: "VolumeWeightedAveragePrice_Period",
    },
    Properties: { Period: "14" },
  },
  {
    Id: "sma_indicator",
    Description: "",
    Type: "Indicators",
    Short: "SMA(Period)",
    Full: "Simple Moving Average",
    Backend: {
      Feature: ["sma_indicator_Period", "sma_indicator", "Period"],
      Usage: "sma_indicator_Period",
    },
    Properties: { Period: "14" },
  },
  {
    Id: "ema_indicator",
    Description: "",
    Type: "Indicators",
    Short: "EMA(Period)",
    Full: "Exponential Moving Average",
    Backend: {
      Feature: ["ema_indicator_Period", "ema_indicator", "Period"],
      Usage: "ema_indicator_Period",
    },
    Properties: { Period: "14" },
  },
  {
    Id: "rsi",
    Description: "",
    Type: "Indicators",
    Short: "RSI(Period)",
    Full: "Relative Strength Index",
    Backend: {
      Feature: ["rsi_Period", "rsi", "Period"],
      Usage: "rsi_Period",
    },
    Properties: { Period: "14" },
  },
  {
    Id: "macd",
    Description: "",
    Type: "Indicators",
    Short: "MacdValue MACD(Fast, Slow, Signal, Offset)",
    Full: "Moving Average Convergence Divergence",
    Backend: {
      Feature: [
        "macd_Fast_Slow_Signal_Offset",
        "macd",
        ["Fast", "Slow", "Signal", "Offset"],
      ],
      Usage: "macd_Fast_Slow_Signal_Offset_MacdValue",
    },
    Properties: {
      MacdValue: ["f", "h", "s"],
      Fast: "12",
      Slow: "26",
      Signal: "9",
      Offset: "0",
    },
  },
  {
    Id: "supertrend",
    Description: "",
    Type: "Indicators",
    Short: "Supertrand(Length, Multiplier, Offset)",
    Full: "Supertrand",
    Backend: {
      Feature: [
        "supertrend_Length_Multiplier_Offset",
        "supertrend",
        ["Length", "Multiplier", "Offset"],
      ],
      Usage: "supertrend_Length_Multiplier_Offset",
    },
    Properties: {
      Length: "7",
      Multiplier: "3",
      Offset: "0",
    },
  },
  {
    Id: "date",
    Description:
      "Number of days to be shifted from current date(0) whole number",
    Type: "Indicators",
    Short: "date(Shifts)",
    Full: "Date",
    Backend: {
      Feature: ["date_Shifts", "date", "Shifts"],
      Usage: "date_Shifts",
    },
    Properties: { Shifts: 1 },
  },
  {
    Id: "time",
    Description: "",
    Type: "Indicators",
    Short: "time(TimeV)",
    Full: "Time.",
    Backend: {
      Feature: ["time_TimeV", "time", "TimeV"],
      Usage: "time_TimeV",
    },
    Properties: { TimeV: "09:25:00" },
  },
  {
    Id: "",
    Description: "",
    Type: "Indicators",
    Short: "Value",
    Full: "Number",
    Backend: {
      Feature: "",
      Usage: "Value",
    },
    Properties: { Value: "100" },
  },
  {
    Id: "shift",
    Description: "",
    Type: "Indicators",
    Short: "shift_Shifts",
    Full: "Shift",
    Backend: {
      Feature: ["shift_ShiftColumn_Shifts", "shift", "ShiftColumn+Shifts"],
      Usage: "shift_ShiftColumn_Shifts",
    },
    Properties: { Shifts: 1 },
  },
];
const Variables = [
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "time",
    Full: "Current Time",
    Backend: {
      Feature: "",
      Usage: "time",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "open",
    Full: "Current Open",
    Backend: {
      Feature: "",
      Usage: "open",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "high",
    Full: "Current High",
    Backend: {
      Feature: "",
      Usage: "high",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "low",
    Full: "Current Low",
    Backend: {
      Feature: "",
      Usage: "low",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "close",
    Full: "Current Close",
    Backend: {
      Feature: "",
      Usage: "close",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "volume",
    Full: "Current Volume",
    Backend: {
      Feature: "",
      Usage: "volume",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "previous day high",
    Full: "Previous Day High",
    Backend: {
      Feature: "",
      Usage: "pre_day_high",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "previous day low",
    Full: "Previous Day Low",
    Backend: {
      Feature: "",
      Usage: "pre_day_low",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "previous day open",
    Full: "Previous Day Open",
    Backend: {
      Feature: "",
      Usage: "pre_day_open",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "previous day close",
    Full: "Previous Day Close",
    Backend: {
      Feature: "",
      Usage: "pre_day_close",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "open price of today",
    Full: "Open Price Of Today",
    Backend: {
      Feature: "",
      Usage: "open_today",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "week day",
    Full: "Week Day",
    Backend: {
      Feature: "",
      Usage: "week_day",
    },
    Properties: {},
  },
];
const MathFunctions = [
  {
    Id: "",
    Description: "",
    Type: "MathFunctions",
    Short: "min",
    Full: "Minimum",
    Backend: {
      Feature: "",
      Usage: "np.minimum",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "MathFunctions",
    Short: "max",
    Full: "Maximum",
    Backend: {
      Feature: "",
      Usage: "np.maximum",
    },
    Properties: {},
  },
];
const Operators = [
  {
    Id: "",
    Description: "",
    Type: "Operators",
    Short: "+",
    Full: "+",
    Backend: {
      Feature: "",
      Usage: "+",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Operators",
    Short: "-",
    Full: "-",
    Backend: {
      Feature: "",
      Usage: "-",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Operators",
    Short: "*",
    Full: "*",
    Backend: {
      Feature: "",
      Usage: "*",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Operators",
    Short: "/",
    Full: "/",
    Backend: {
      Feature: "",
      Usage: "/",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Operators",
    Short: "%",
    Full: "%",
    Backend: {
      Feature: "",
      Usage: "%",
    },
    Properties: {},
  },
];
const LogicalOperators = [
  {
    Id: "",
    Description: "",
    Type: "LogicalOperators",
    Short: "and",
    Full: "And.",
    Backend: {
      Feature: "",
      Usage: "&",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "LogicalOperators",
    Short: "or",
    Full: "Or.",
    Backend: {
      Feature: "",
      Usage: "|",
    },
    Properties: {},
  },
];
const Comparators = [
  {
    Id: "",
    Description: "",
    Type: "Comparators",
    Short: "lower than",
    Full: "Lower Than.",
    Backend: {
      Feature: "",
      Usage: "<",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Comparators",
    Short: "higher than",
    Full: "Higher Than.",
    Backend: {
      Feature: "",
      Usage: ">",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Comparators",
    Short: "lower than equal to",
    Full: "Lower Than Equal To",
    Backend: {
      Feature: "",
      Usage: "<=",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Comparators",
    Short: "higher than equal to",
    Full: "Higher Than Equal To",
    Backend: {
      Feature: "",
      Usage: ">=",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Comparators",
    Short: "equal to",
    Full: "Equal To.",
    Backend: {
      Feature: "",
      Usage: "==",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Comparators",
    Short: "not equal to",
    Full: "Not Equal To",
    Backend: {
      Feature: "",
      Usage: "!=",
    },
    Properties: {},
  },
];
const Brackets = [
  {
    Id: "",
    Description: "",
    Type: "Brackets",
    Short: "(",
    Full: "(",
    Backend: {
      Feature: "",
      Usage: "(",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Brackets",
    Short: ")",
    Full: ")",
    Backend: {
      Feature: "",
      Usage: ")",
    },
    Properties: {},
  },
];
const Others = [
  {
    Id: "",
    Description: "",
    Type: "Others",
    Short: "crosses above",
    Full: "Crosses Above",
    Backend: {
      Feature: "",
      Usage: "",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Others",
    Short: "crosses below",
    Full: "Crosses Below",
    Backend: {
      Feature: "",
      Usage: "",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Others",
    Short: "up by Value Type from",
    Full: "Up By",
    Backend: {
      Feature: "",
      Usage: "",
    },
    Properties: {
      Type: ["%", "abs"],
      Value: 15,
    },
  },
  {
    Id: "",
    Description: "",
    Type: "Others",
    Short: "down by Value Type from",
    Full: "Down By",
    Backend: {
      Feature: "",
      Usage: "",
    },
    Properties: {
      Type: ["%", "abs"],
      Value: 15,
    },
  },
];

export const conditionCollectionMain = {
  All: [...Indicators, ...Variables, ...MathFunctions, ...Brackets, ...Operators,
  ...LogicalOperators,
  ...Comparators,
  ...Others,
  ],
  Indicators: Indicators,
  Variables: Variables,
  MathFunctions: MathFunctions,
  Brackets: Brackets,
  Operators: Operators,
  LogicalOperators: LogicalOperators,
  Comparators: Comparators,
  Others: Others,

};
export const conditionCollectionScnd = {
  All: [
    ...Operators,
    ...LogicalOperators,
    ...Comparators,
    ...Others,
    ...Brackets,
  ],
  Operators: Operators,
  LogicalOperators: LogicalOperators,
  Comparators: Comparators,
  Others: Others,
  Brackets: Brackets,
};
