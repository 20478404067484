import React, { useEffect, useRef } from "react";
import ConditionBuilder from "./ConditionBuilder";
import { Dropdown } from "./FormCompos";

const EntryCondition = ({ getdata, resetGetData, EntryCondData, setentryCondData }) => {
  const conditionBuilderRef1 = useRef(null); // Ref for first ConditionBuilder
  const conditionBuilderRef2 = useRef(null); // Ref for second ConditionBuilder

  useEffect(() => {
    if (getdata) {
      if (conditionBuilderRef1.current) {
        conditionBuilderRef1.current.triggerUpdate();
      }
      if (conditionBuilderRef2.current) {
        conditionBuilderRef2.current.triggerUpdate();
      }
      setTimeout(() => resetGetData(), 500); // Corrected reset function
    }
  }, [getdata, resetGetData]);

  const handleUpdate = (field, updatedConditions) => {
    setentryCondData((prev) => ({
      ...prev,
      [field]: updatedConditions,
    }));
  };

  const handleAndOr = (field, value) => {
    setentryCondData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleDelete = (field) => {
    setentryCondData((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "left", gap: "1rem" }} >
      <h3 style={{ align: "left", fontSize: "1rem", marginBottom: "0rem" }}>Entry Condition</h3>

      <ConditionBuilder
        ref={conditionBuilderRef1}
        label="Entry Condition"
        onUpdate={(updatedConditions) => handleUpdate("ent_cond", updatedConditions)}
        onDelete={() => handleDelete("ent_cond")}
      />

      <Dropdown
        label="Exit Entry condition combined"
        options={["none", "and", "or"]}
        value={EntryCondData.ext_ent_cond_comb}
        onChange={(value) => handleAndOr("ext_ent_cond_comb", value)}
      />

      <ConditionBuilder
        ref={conditionBuilderRef2}
        label="Entry Exit Condition"
        onUpdate={(updatedConditions) => handleUpdate("ext_ent_cond", updatedConditions)}
        onDelete={() => handleDelete("ext_ent_cond")}
      />

    </div>
  );
};

export default EntryCondition;
