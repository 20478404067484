
import React, { useState, useMemo } from "react";
import { conditionCollectionMain } from "./indicators";
import "../../styles/IndicatorDropdown.css";

const IndicatorDropdown = React.memo(({ onUpdate, onDelete }) => {

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedIndicator, setSelectedIndicator] = useState(null);
    const [properties, setProperties] = useState();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [, setIsDropdownOpen] = useState(false);

    const allIndicators = useMemo(() => conditionCollectionMain.All, []);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const handleSelect = (indicator) => {
        if (!indicator.CurrentProperties) {

            let condname = indicator.Short;
            let defaultvalues = {};

            let propers = Object.keys(indicator.Properties);
            propers.map((props) => {
                condname = condname.replace(
                    props,
                    Array.isArray(indicator.Properties[props])
                        ? indicator.Properties[props][0]
                        : indicator.Properties[props]
                );

                defaultvalues[props] = Array.isArray(indicator.Properties[props])
                    ? indicator.Properties[props][0]
                    : indicator.Properties[props];
            });

            indicator["CurrentProperties"] = defaultvalues;
            indicator["Name"] = condname;
        }

        indicator["CurrentBackend"] = indicator.Backend;
        const clonedIndicator = JSON.parse(JSON.stringify(indicator)); // Deep clone
        setSelectedIndicator(clonedIndicator);
        const properties = indicator.Properties || {};
        setProperties(properties);

        Object.entries(properties).forEach(([key, value]) => {
            retriveLastCurrentProperties(key, indicator.CurrentProperties[key], Array.isArray(value));
        });
        setIsPopupOpen(true);
        setIsDropdownOpen(false);

    };

    const retriveLastCurrentProperties = (key, value, isDropdown) => {
        if (!isDropdown) {
            setProperties((prev) => ({ ...prev, [key]: value }));
        }
        else {
            setProperties((prev) => ({
                ...prev,
                [key]: [value, ...(prev[key] || []).filter((item) => item !== value)],
            }));
        }
    };

    const handleToggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
        if (selectedIndicator) { handleSelect(selectedIndicator); }
    };

    const handlePropertyChange = (key, value, isDropdown) => {
        if (!isDropdown) {
            setProperties((prev) => ({ ...prev, [key]: value }));
        }
        else {
            setProperties((prev) => ({
                ...prev,
                [key]: [value, ...(prev[key] || []).filter((item) => item !== value)],
            }));
        }
        selectedIndicator.CurrentProperties[key] = value;
    };
    const replaceKeysInObject = (obj, mapping) => {
        if (Array.isArray(obj)) {
            return obj.map(item => replaceKeysInObject(item, mapping));
        } else if (typeof obj === 'object' && obj !== null) {
            return Object.fromEntries(
                Object.entries(obj).map(([key, value]) => [
                    key,
                    replaceKeysInObject(value, mapping)
                ])
            );
        } else if (typeof obj === 'string') {
            return obj.split('_').map(word => mapping[word] || word).join('_');
        }
        return obj;
    };

    const handleDone = () => {

        const propers = selectedIndicator.CurrentProperties || {};
        var updatedString = selectedIndicator.Short
        updatedString = updatedString.replace(/\b\w+\b/g, (word) => {
            return propers[word] || word;
        });
        selectedIndicator.Name = updatedString;
        const updatedBackend = replaceKeysInObject(selectedIndicator.CurrentBackend, selectedIndicator.CurrentProperties);
        selectedIndicator.CurrentBackend = updatedBackend;
        selectedIndicator.Id = selectedIndicator.CurrentBackend.Usage + Date.now();
        onUpdate(selectedIndicator.CurrentBackend);

        setIsPopupOpen(false);

    };

    const handleDelete = () => {
        setSelectedIndicator(null);
        setProperties({});
        setIsPopupOpen(false);
    };

    const filteredIndicators = searchTerm.trim() === ""
        ? allIndicators
        : allIndicators.filter((indicator) =>
            indicator.Full.toLowerCase().includes(searchTerm.trim()) ||
            indicator.Short.toLowerCase().includes(searchTerm.trim())
        );

    return (
        <div className="dropdown">
            <button className="dropdown-button" onClick={(e) => { e.preventDefault(); handleToggleDropdown(); }}>
                {selectedIndicator
                    ? `${selectedIndicator.Name}`
                    : (
                        <span className="add-text">+ ADD</span>
                    )}
            </button>
            <button className="delete-condition" onClick={(e) => { e.preventDefault(); onDelete(); }} >
                ✕ </button>
            <div className="dropdown-content">
                <input
                    type="text"
                    placeholder="Search ..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="search-input"
                />
                <div className="grid-container">
                    {filteredIndicators.length > 0 ? (
                        filteredIndicators.map((indicator) => (
                            <div
                                key={indicator.Short}
                                className="grid-item"
                                onClick={() => handleSelect(indicator)}
                            >
                                {indicator.Short}
                            </div>
                        ))
                    ) : (
                        <div className="no-results">No Results Found</div>
                    )}
                </div>

            </div>

            {/* Popup Window */}
            {isPopupOpen && (
                <div className="popup-overlay">
                    <div className="popup">
                        <h3>Set {selectedIndicator.Full}</h3>
                        <div className="properties-container">
                            {Object.entries(properties).map(([key, value]) => (
                                <div key={key} className="property-item">
                                    <label>{key}</label>
                                    {Array.isArray(value) ? (
                                        <select
                                            onChange={(e) => handlePropertyChange(key, e.target.value, true)}
                                            value={value.selected || ""}
                                        >
                                            {value.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <input
                                            type="text"
                                            value={value}
                                            onChange={(e) => handlePropertyChange(key, e.target.value, false)}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className="popup-buttons">
                            <button className="btn-done" onClick={handleDone}>
                                Save
                            </button>
                            <button className="btn-delete" onClick={handleDelete}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});

export default IndicatorDropdown;
