import axios from 'axios';

const api = axios.create({
  baseURL: "http://console.optalpha.com:7781",
});

// Attach token to every request
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const api7777 = axios.create({
  baseURL: "http://console.optalpha.com:7777",
});

// Attach token to every request
api7777.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
export { api7777 };
