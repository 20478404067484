import React, { useEffect, useRef } from "react";
import ConditionBuilder from "./ConditionBuilder";
import { TextInput, Dropdown } from "./FormCompos";
import "../../styles/ConditionBuilder.css"
const PExitCondition = ({ label = "", getdata, resetGetData, contracts, PExitCondData, setPExitCondData }) => {
    const conditionBuilderRef = useRef(null);
    useEffect(() => {
        if (getdata && conditionBuilderRef.current) {
            conditionBuilderRef.current.triggerUpdate();
            setTimeout(() => resetGetData(false), 500);
        }
    }, [getdata, resetGetData]);

    const handleUpdate = (field, updatedConditions) => {
        setPExitCondData({
            ...PExitCondData,
            [field]: updatedConditions,
        });
        console.log("pexit", PExitCondData);

    };

    const handleDelete = (field) => {
        setPExitCondData({
            ...PExitCondData,
            [field]: [],
        });
    }

    const contractOptions = Array.isArray(contracts)
        ? ["under", ...contracts.map((_, index) => `op${index + 1}_`)] // Add default option
        : ["under"];

    const handleChange = (field, value) => {
        setPExitCondData({
            ...PExitCondData,
            partial_exit_price_change: {
                ...PExitCondData.partial_exit_price_change,
                [field]: value,
            },
        });
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "left", gap: "1rem" }} >

            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
                <Dropdown
                    label="OnWhat"
                    options={contractOptions}
                    value={PExitCondData.partial_exit_price_change.name}
                    onChange={(value) => handleChange("name", value)}
                />
                <TextInput
                    label="Stoploss"
                    value={PExitCondData.partial_exit_price_change.stoploss}
                    onChange={(value) => handleChange("stoploss", value)}
                    placeholder=""
                />
                <TextInput
                    label="Target"
                    value={PExitCondData.partial_exit_price_change.target}
                    onChange={(value) => handleChange("target", value)}
                    placeholder=""
                />
                <ConditionBuilder
                    ref={conditionBuilderRef}
                    label={label}
                    onUpdate={(updatedConditions) => handleUpdate("partial_ext_cond", updatedConditions)}
                    onDelete={() => handleDelete("partial_ext_cond")}
                />
            </div>

        </div>
    );
};

export default PExitCondition;
