class Strategy {
    constructor() {
        this.name = "BANKNIFTY";
        this.transaction_type = "BUY";
        this.time_frame_minutes = 1;
        this.ent_lots = 1;

        this.option_contracts = [];

        this.hold_conds = [];

        this.ent_cond = [{ id: Date.now(), element: null },];
        this.ext_ent_cond_comb = "none";
        this.ext_ent_cond = [{ id: Date.now(), element: null },];

        this.partial_exits = [];

        this.exit_price_change = { "name": "", "stoploss": "", "target": "" };
        this.ext_cond = [{ id: Date.now(), element: null },];

        this.option_ent_within_mins = 5;
        this.option_ext_within_mins = 375;

    }
    updateSection(section, data) {
        if (this.hasOwnProperty(section)) {
            this[section] = data;
        }
    }
}
export default Strategy;
