import InstrumentDropdown from "./InstrumentDropdown";
import OptionContract from "./OptionContract";
import { TextInput, Dropdown } from "./FormCompos";
import EntryCondition from "./EntryCondition";
import HoldCondition from "./HoldCondition";
import ExitCondition from "./ExitCondition";
import PartialExitCond from "./PartialExitCond";
import "../../styles/Template.css";

const StrategyUI = ({ StrategyData, setStrategyData }) => {

  const fieldInfo = {
    name: "Enter the instrument name, /n e.g., BANKNIFTY.",
    transaction_type: "Select whether to BUY or SELL.",
    time_frame_minutes: "Choose the candle interval in minutes.",
    ent_lots: "Specify the number of lots for entry (whole number > 0).",
    option_ent_within_mins: "Time limit for option entry in minutes.",
    option_ext_within_mins: "Time limit for option exit in minutes."
  };

  const updateData = (section, value) => {
    setStrategyData((prev) => ({
      ...prev,
      [section]: value,
    }));
  };

  return (
    <form className="TemplateContainer">
      <div className="TemplateCard">
        <div className="InstrumentCard">
          <InstrumentDropdown
            label="Instrument Name"
            selectedInstrument={StrategyData.name}
            setSelectedInstrument={(value) => {
              updateData("name", value);
              if (["NIFTY", "BANKNIFTY"].includes(value)) {
                updateData("ent_lots", 0);
              }
            }}
          />
          <Dropdown
            label="Entry Type"
            options={["BUY", "SELL"]}
            value={StrategyData.transaction_type}
            onChange={(value) => updateData("transaction_type", value)}
          />
          <Dropdown
            label="Candle Interval(min)"
            options={Array.from({ length: 375 }, (_, i) => (i + 1).toString())}
            value={StrategyData.time_frame_minutes}
            onChange={(value) => updateData("time_frame_minutes", Number(value))}
          />
          <TextInput
            label="# Entry Lots"
            value={StrategyData.ent_lots}
            onChange={(value) => {
              if (["NIFTY", "BANKNIFTY"].includes(StrategyData.name)) return;
              if (value === "" || /^-?\d+$/.test(value)) {
                updateData("ent_lots", value === "" ? "" : parseInt(value, 10));
              }
            }}
            placeholder=""
            fieldInfo={fieldInfo.ent_lots}
          />
        </div>
      </div>

      <div className="TemplateCard">
        <OptionContract
          OcLen={StrategyData.option_contracts.length}
          holdCondLen={StrategyData.hold_conds.length}
          contracts={StrategyData.option_contracts}
          setContracts={(newContracts) => updateData("option_contracts", newContracts)}
        />
      </div>
      <div className="TemplateCard">
        <HoldCondition
          OcLen={StrategyData.option_contracts.length}
          holdConditions={StrategyData.hold_conds || []}
          setHoldConditions={(newConditions) => {
            if (!Array.isArray(newConditions)) {
              return;
            }
            updateData("hold_conds", newConditions);
          }} />
      </div>
      <div className="TemplateCard">
        <EntryCondition
          OcLen={StrategyData.option_contracts.length}
          holdCondLen={StrategyData.hold_conds.length}
          EntryCondData={StrategyData}
          setentryCondData={(newEntryCond) => {
            updateData("ent_cond", newEntryCond.ent_cond);
            updateData("ext_ent_cond_comb", newEntryCond.ext_ent_cond_comb);
            updateData("ext_ent_cond", newEntryCond.ext_ent_cond);
          }}
        />
      </div>
      <div className="TemplateCard">
        <PartialExitCond
          OcLen={StrategyData.option_contracts.length}
          holdCondLen={StrategyData.hold_conds.length}
          contracts={StrategyData.option_contracts}
          formData={StrategyData}
          partialExitCond={StrategyData.partial_exits}
          setPartialExitCond={(newPartialExit) => updateData("partial_exits", newPartialExit)}
        />
      </div>
      <div className="TemplateCard">
        <h5 style={{ align: "left", fontSize: "1rem" }}>Exit Condition</h5>
        <ExitCondition
          OcLen={StrategyData.option_contracts.length}
          holdCondLen={StrategyData.hold_conds.length}
          label="Exit Condition"
          contracts={StrategyData.option_contracts}
          ExitCondData={StrategyData}
          setexitCondData={(newExitCond) => {
            updateData("exit_price_change", newExitCond.exit_price_change);
            updateData("ext_cond", newExitCond.ext_cond);

          }}
        />
      </div>
      <div className="TemplateCard">
        <div className="InstrumentCard">
          <TextInput
            label="Option entry within mins"
            value={StrategyData.option_ent_within_mins}
            onChange={(value) => updateData("option_ent_within_mins", value)}
            placeholder="minutes"
          />
          <TextInput
            label="Option exit within mins"
            value={StrategyData.option_ext_within_mins}
            onChange={(value) => updateData("option_ext_within_mins", value)}
            placeholder="minutes"
          />
        </div>

      </div>
    </form>
  );
};

export default StrategyUI;
