import { TextInput, Dropdown, IconButton, Label } from './FormCompos';
import React, { useState } from "react";
import ConditionBuilder from "./ConditionBuilder";
import "../../styles/IndicatorDropdown.css";
const OptionContract = ({ OcLen, holdCondLen, contracts, setContracts }) => {

  const fieldInfo = {
    contract_type: "**ocae:** Option Contract at Entry /n **ocer:** Option Contract at Every Row /n   **customize:** your own contract type",
    expiry: "**Week_0:** Current Week /n **Week_1:** Next Week /n **Week_2:** Next to next Week/n /n and so on..",
    strike: "strike... ....... ...... ...... ...."
  };

  const addContract = () => {
    setContracts([
      ...contracts,
      {
        contract_type: "ocae",
        transaction_type: "BUY",
        option_type: "CE",
        strike: "0",
        expiry: "Week_0",
        ent_lots: 1,
      },
    ]);
  };

  const deleteContract = (index) => {
    if (index >= 0) {
      setContracts(contracts.filter((_, i) => i !== index));
    }
  };

  const handleContractChange = (index, field, value) => {
    const updatedContracts = [...contracts];
    if (field === "contract_type" && value === "customize") {
      updatedContracts[index]["customized_contract_condition"] = [{ id: Date.now(), element: null }];
    }
    updatedContracts[index][field] = value;
    setContracts(updatedContracts);

  };

  const handleCustomizeConfirm = (conditions, index) => {
    const updatedContracts = [...contracts];
    updatedContracts[index]["customized_contract_condition"] = conditions;
    setContracts(updatedContracts);
  };

  return (
    <div>
      <div style={{ display: "flex", gap: "2rem" }}>
        <h3 style={{ fontSize: "1rem" }}>Option Contracts</h3>
        <IconButton icon="+" label="" onClick={(e) => { e.preventDefault(); addContract(); }} />
      </div>
      {contracts.map((contract, index) => (
        <div
          key={`op${index + 1}_`}
          style={{ padding: "0.5rem ", display: "flex", flexWrap: "wrap", gap: "1rem", height: 'auto', }}  >
          <Label text={`op${index + 1}_`} />

          <Dropdown
            label="Contract Type"
            options={["ocae", "ocer", "customize"]}
            value={contract.contract_type}
            onChange={(value) => handleContractChange(index, "contract_type", value)}
            fieldInfo={fieldInfo.contract_type}
          />

          <Dropdown
            label="Entry Type"
            options={["BUY", "SELL"]}
            value={contract.transaction_type}
            onChange={(value) => handleContractChange(index, "transaction_type", value)}
          />

          <Dropdown
            label="Right"
            options={["CE", "PE"]}
            value={contract.option_type}
            onChange={(value) => handleContractChange(index, "option_type", value)}
          />

          <TextInput
            label="Strike"
            value={contract.strike}
            onChange={(value) => {
              if (value === "" || value === "-" || /^-?\d+$/.test(value)) {
                handleContractChange(index, "strike", value);
              } else if (!/^-?\d*$/.test(value)) { return; }
            }}
            placeholder="0"
            fieldInfo={fieldInfo.strike}
          />

          <Dropdown
            label="Expiry"
            options={["Week_0", "Week_1", "Week_2", "Month_0", "Month_1"]}
            value={contract.expiry}
            onChange={(value) => handleContractChange(index, "expiry", value)}
            fieldInfo={fieldInfo.expiry}
          />

          <TextInput
            label="#Lots"
            value={contract.ent_lots}
            onChange={(value) => {
              if (value === "" || /^-?\d+$/.test(value)) {
                handleContractChange(index, "ent_lots", value === "" ? "" : Number(value));
              }
            }}
            placeholder="1"
          />


          <IconButton icon="+" label="" onClick={(e) => { e.preventDefault(); addContract(); }} />
          <IconButton icon="-" label="" onClick={(e) => { e.preventDefault(); deleteContract(index); }} />

          {contract.contract_type === "customize" && (
            <div style={{ marginBottom: "1rem" }}>
              <ConditionBuilder
                OcLen={OcLen}
                holdCondLen={holdCondLen}
                label="Customize Contract Condition"
                localConditions={contract.customized_contract_condition}
                setLocalConditions={(conditions) => handleCustomizeConfirm(conditions, index)}
              />
            </div>
          )}
        </div>
      ))}


    </div>
  );
};

export default OptionContract;