import { TextInput, Dropdown, IconButton, Label } from './FormCompos';
import React from "react";

const OptionContract = ({ contracts, setContracts }) => {

  const addContract = () => {
    setContracts([
      ...contracts,
      {
        contract_type: "ocae",
        transaction_type: "BUY",
        option_type: "CE",
        strike: "0",
        expiry: "Week_0",
        ent_lots: 1,
      },
    ]);
  };

  const deleteContract = (index) => {
    if (index >= 0) {
      setContracts(contracts.filter((_, i) => i !== index));
    }
  };

  const handleContractChange = (index, field, value) => {
    const updatedContracts = [...contracts];
    updatedContracts[index][field] = value;
    setContracts(updatedContracts);
  };

  return (
    <div>
      <div style={{ display: "flex", gap: "2rem" }}>
        <h3 style={{ fontSize: "1rem" }}>Option Contracts</h3>
        <IconButton icon="+" label="" onClick={(e) => { e.preventDefault(); addContract(); }} />
      </div>
      {contracts.map((contract, index) => (
        <div
          key={`op${index + 1}_`}
          style={{ padding: "0.5rem ", display: "flex", flexWrap: "wrap", gap: "1rem", }}
        >
          <Label text={`op${index + 1}_`} />
          <Dropdown
            label="Contract Type"
            options={["ocae", "ocer", "customize"]}
            value={contract.contract_type}
            onChange={(value) => handleContractChange(index, "contract_type", value)}
          />
          <Dropdown
            label="Entry Type"
            options={["BUY", "SELL"]}
            value={contract.transaction_type}
            onChange={(value) => handleContractChange(index, "transaction_type", value)}
          />
          <Dropdown
            label="Right"
            options={["CE", "PE"]}
            value={contract.option_type}
            onChange={(value) => handleContractChange(index, "option_type", value)}
          />
          <TextInput
            label="Strike"
            value={contract.strike}
            onChange={(value) => handleContractChange(index, "strike", value)}
            placeholder="0"
          />
          <Dropdown
            label="Expiry"
            options={["Week_0", "Week_1", "Week_2", "Month_0", "Month_1"]}
            value={contract.expiry}
            onChange={(value) => handleContractChange(index, "expiry", value)}
          />
          <TextInput
            label="#Lots"
            value={contract.ent_lots}
            onChange={(value) => handleContractChange(index, "ent_lots", value)}
            placeholder="1"
          />
          <IconButton icon="+" label="" onClick={(e) => { e.preventDefault(); addContract(); }} />
          <IconButton icon="-" label="" onClick={(e) => { e.preventDefault(); deleteContract(index); }} />
        </div>
      ))}
    </div>
  );
};

export default OptionContract;