import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = ({ isAdmin, onLogout }) => {
  const location = useLocation();
  return (
    <nav className="navbar" >
      <ul className="navbar-links">
        {isAdmin && (
          <>
            <li><Link to="/users" className={location.pathname === '/users' ? 'active' : ''} >
              Users
            </Link></li>
            <li><Link to="/models" className={location.pathname === '/models' ? 'active' : ''}>
              Models
            </Link></li>
            <li><Link to="/historicalTrades " className={location.pathname === '/historicalTrades' ? 'active' : ''} >
              Historical Trades
            </Link></li>
            <li><Link to="/liveTrades" className={location.pathname === '/liveTrades' ? 'active' : ''} >
              Live Trades
            </Link></li>
            <li><Link to="/tickerData" className={location.pathname === '/tickerData' ? 'active' : ''} >
              Ticker Data
            </Link></li>
            <li><Link to="/logs" className={location.pathname === '/logs' ? 'active' : ''} >
              Logs
            </Link></li>
          </>
        )}

        <li><Link to="/template" className={location.pathname === '/template' ? 'active' : ''} >
          Generate Template
        </Link></li>
        <li><Link to="/backtest" className={location.pathname === '/backtest' ? 'active' : ''} >
          Backtest
        </Link></li>

      </ul>
      <button
        onClick={onLogout}
        onMouseOver={(e) => (e.target.style.background = '#c0392b')}
        onMouseOut={(e) => (e.target.style.background = '#e74c3c')}
      >
        Logout
      </button>
    </nav>
  );
}

export default Navbar;