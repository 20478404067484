import React, { useState } from "react";
import { Dropdown, IconButton } from "./FormCompos";
import ExitCondition from "./ExitCondition";
import PExitCondition from "./PExitCondition";

import "../../styles/Template.css";

const PartialExitCond = ({ getdata, resetGetData, contracts, formData, partialExitCond, setPartialExitCond }) => {
    const addPartialExitCond = () => {
        setPartialExitCond([
            ...partialExitCond,
            {
                lots: contracts.reduce((acc, _, index) => {
                    acc[`op${index + 1}_`] = 0;
                    return acc;
                }, {}),
                partial_exit_price_change: { name: "under", pos: "", neg: "", stoploss: "", target: "" },
                partial_ext_cond: [],
                exit_price_change: { name: "under", pos: "", neg: "", stoploss: "", target: "" },
                ext_cond: []
            }
        ]);
    };

    // Function to remove a Partial Exit Condition by index
    const removePartialExitCond = (index) => {
        if (index >= 0) { setPartialExitCond(partialExitCond.filter((_, i) => i !== index)); }
    };

    // Function to update a specific Partial Exit Condition
    const updatePartialExitCond = (index, updatedData) => {
        setPartialExitCond((prev) =>
            prev.map((item, i) => (i === index ? updatedData : item))
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "left", gap: "1rem" }}>
            <div style={{ display: "flex", gap: "30px" }}><h3 style={{ fontSize: "1rem" }}>Partial Exit </h3>
                <IconButton icon="+" label="" onClick={(e) => { e.preventDefault(); addPartialExitCond(); }} />
            </div>
            {partialExitCond.map((onePartialExitCond, index) => (
                <div key={index} style={{ border: "1px solid #ccc", padding: "10px", display: "flex", flexDirection: "column", alignItems: "left", gap: "1rem" }}>
                    <div style={{ display: "flex", gap: "30px" }}><h5>Partial Exit Condition {index + 1}</h5><IconButton icon="-" label="" onClick={(e) => { e.preventDefault(); removePartialExitCond(index); }} />
                    </div>

                    <div style={{ display: "flex", gap: "30px", padding: "10px" }}>
                        <Dropdown
                            label={`#under_Lots`}
                            options={Array.from({ length: parseInt(formData.ent_lots, 10) + 1 }, (_, i) => i.toString())}
                            value={onePartialExitCond.lots[`under`] || 0}
                            onChange={(value) => {
                                updatePartialExitCond(index, {
                                    ...onePartialExitCond,
                                    lots: { ...onePartialExitCond.lots, under: parseInt(value, 10) }
                                });
                            }}
                        />
                        {contracts.map((contract, idx) => (
                            <div key={idx} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Dropdown
                                    label={`#op${idx + 1}_Lots`}
                                    options={Array.from({ length: parseInt(contract.ent_lots, 10) + 1 }, (_, i) => i.toString())}
                                    value={onePartialExitCond.lots[`op${idx + 1}_`] || 0}
                                    onChange={(value) => {
                                        updatePartialExitCond(index, {
                                            ...onePartialExitCond,
                                            lots: { ...onePartialExitCond.lots, [`op${idx + 1}_`]: parseInt(value, 10) }
                                        });
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                    <PExitCondition
                        label="Partial Exit Condition"
                        getdata={getdata}
                        resetGetData={() => setTimeout(() => resetGetData(false), 400)}
                        contracts={contracts}
                        PExitCondData={{
                            partial_exit_price_change: onePartialExitCond.partial_exit_price_change,
                            partial_ext_cond: onePartialExitCond.partial_ext_cond
                        }}

                        setPExitCondData={(updatedData) => {
                            setPartialExitCond((prev) =>
                                prev.map((item, i) => {
                                    if (i !== index) return item;
                                    const newData = typeof updatedData === "function" ? updatedData(item) : updatedData;
                                    return {
                                        ...item,
                                        partial_exit_price_change: {
                                            ...item.partial_exit_price_change,
                                            ...newData.partial_exit_price_change
                                        },
                                        partial_ext_cond: newData.partial_ext_cond || item.partial_ext_cond,
                                        ext_cond: item.ext_cond
                                    };
                                })
                            );
                        }}
                    />

                    <ExitCondition
                        label="Exit Condition"
                        getdata={getdata}
                        resetGetData={() => setTimeout(() => resetGetData(false), 200)}
                        contracts={contracts}
                        ExitCondData={{
                            exit_price_change: onePartialExitCond.exit_price_change,
                            ext_cond: onePartialExitCond.ext_cond
                        }}

                        setexitCondData={(updatedData) => {
                            setPartialExitCond((prev) =>
                                prev.map((item, i) => {
                                    if (i !== index) return item;
                                    const newData = typeof updatedData === "function" ? updatedData(item) : updatedData;

                                    return {
                                        ...item,
                                        exit_price_change: {
                                            ...item.exit_price_change,
                                            ...newData.exit_price_change
                                        },
                                        ext_cond: newData.ext_cond || item.ext_cond,
                                        partial_ext_cond: item.partial_ext_cond
                                    };
                                })
                            );
                        }}
                    />
                </div>
            ))}
        </div>
    );
};

export default PartialExitCond;