const Indicators = [
  {
    Id: "prv_cand",
    Description: "",
    Type: "Indicators",
    Short: "CandleValue Prev_Candle(Shifts)",
    Full: "Previous Candle",
    Backend: {
      Feature: {
        "feature_name": "prv_cand_Shifts",
        "feature_type": "prv_cand",
        "feature_value": "Shifts"
      },
      Usage: "CandleValue_prv_cand_Shifts",
    },
    Properties: {
      CandleValue: { 1: "open", 2: "high", 3: "low", 4: "close" },
      Shifts: 1
    },
  },
  {
    Id: "cand",
    Description: "",
    Type: "Indicators",
    Short: "CandleValue Candle(Timeframe)",
    Full: "Day Start Candle In MIN",
    Backend: {
      Feature: {
        "feature_name": "cand_Timeframe",
        "feature_type": "cand",
        "feature_value": "Timeframe"
      },
      Usage: "CandleValue_cand_Timeframe",
    },
    Properties: {
      CandleValue: { 1: "high", 2: "low", 3: "close" },
      Timeframe: 15,
    },
  },
  {
    Id: "pivot",
    Description: "",
    Type: "Indicators",
    Short: "PIVOTV Pivot",
    Full: "Support Resistances Pivot",
    Backend: {
      Feature: {
        "feature_name": "pivot",
        "feature_type": "pivot",
        "feature_value": ""
      },
      Usage: "PIVOTV_pivot",
    },
    Properties: {
      PIVOTV: { 1: "r1", 2: "r2", 3: "r3", 4: "s1", 5: "s2", 6: "s3", 7: "p" },
    },
  },
  {
    Id: "resample",
    Description: "",
    Type: "Indicators",
    Short: "CandleValue Resample(Timeframe)",
    Full: "Multi Timeframe Resample In MIN",
    Backend: {
      Feature: {
        "feature_name": "resample_Timeframe",
        "feature_type": "resample",
        "feature_value": "Timeframe"
      },
      Usage: "CandleValue_resample_Timeframe",
    },
    Properties: {
      CandleValue: { 1: "open", 2: "high", 3: "low", 4: "close" },
      Timeframe: "1",
    },
  },
  {
    Id: "stochrsi",
    Description: "",
    Type: "Indicators",
    Short: "StochRSI(Period)",
    Full: "StochRSI",
    Backend: {
      Feature: {
        "feature_name": "stochrsi_Period",
        "feature_type": "stochrsi",
        "feature_value": "Period"
      },
      Usage: "stochrsi_Period",
    },
    Properties: { Period: "14" },
  },
  {
    Id: "williams_r",
    Description: "",
    Type: "Indicators",
    Short: "WilliamsR(Lbp)",
    Full: "Williams %R",
    Backend: {
      Feature: {
        "feature_name": "williams_r_Lbp",
        "feature_type": "williams_r",
        "feature_value": "Lbp"
      },
      Usage: "williams_r_Lbp",
    },
    Properties: { Lbp: "14" },
  },
  {
    Id: "donchian_channel_pband",
    Description: "",
    Type: "Indicators",
    Short: "Donchian(Period)",
    Full: "Donchian Channel Pband",
    Backend: {
      Feature: {
        "feature_name": "donchian_channel_pband_Period",
        "feature_type": "donchian_channel_pband",
        "feature_value": "Period"
      },
      Usage: "donchian_channel_pband_Period",
    },
    Properties: { Period: "14" },
  },
  {
    Id: "average_true_range",
    Description: "",
    Type: "Indicators",
    Short: "ATR(Period)",
    Full: "Average True Range",
    Backend: {
      Feature: {
        "feature_name": "average_true_range_Period",
        "feature_type": "average_true_range",
        "feature_value": "Period"
      },
      Usage: "average_true_range_Period",
    },
    Properties: { Period: "14" },
  },
  {
    Id: "vcp_bullish",
    Description: "",
    Type: "Indicators",
    Short: "VCPProperties VCPBU(windowArray, spread_reference, perc_retracement_low, perc_retracement_high)",
    Full: "Volatility Contraction Pattern Bullish",
    Backend: {
      Feature: {
        "feature_name": "vcp_bullish_windowArray_spread_reference_perc_retracement_low_perc_retracement_high",
        "feature_type": "vcp_bullish",
        "feature_value": ["windowArray", "spread_reference", "perc_retracement_low", "perc_retracement_high"]
      },
      Usage: "VCPProperties_vcp_bullish_windowArray_spread_reference_perc_retracement_low_perc_retracement_high",
    },
    Properties: { VCPProperties: { 1: "bn", 2: "f", 3: "hb", 4: "lb", 5: "pr", 6: "c", 7: "av" }, windowArray: ["25", "50"], spread_reference: "0.005", perc_retracement_low: "0.4", perc_retracement_high: "0" },
  },
  {
    Id: "vcp_bearish",
    Description: "",
    Type: "Indicators",
    Short: "VCPProperties VCPBE(windowArray, spread_reference, perc_retracement_low, perc_retracement_high)",
    Full: "Volatility Contraction Pattern Bearish",
    Backend: {
      Feature: {
        "feature_name": "vcp_bearish_windowArray_spread_reference_perc_retracement_low_perc_retracement_high",
        "feature_type": "vcp_bearish",
        "feature_value": ["windowArray", "spread_reference", "perc_retracement_low", "perc_retracement_high"]
      },
      Usage: "VCPProperties_vcp_bearish_windowArray_spread_reference_perc_retracement_low_perc_retracement_high",
    },
    Properties: { VCPProperties: { 1: "bn", 2: "f", 3: "hb", 4: "lb", 5: "pr", 6: "c", 7: "av" }, windowArray: ["25", "50"], spread_reference: "10.005", perc_retracement_low: "01.4", perc_retracement_high: "10" },
  },
  {
    Id: "VolumeWeightedAveragePrice",
    Description: "",
    Type: "Indicators",
    Short: "VWAP(Period)",
    Full: "Volume Waighted Avg Price",
    Backend: {
      Feature: {
        "feature_name": "VolumeWeightedAveragePrice_Period",
        "feature_type": "VolumeWeightedAveragePrice",
        "feature_value": "Period"
      },
      Usage: "VolumeWeightedAveragePrice_Period",
    },
    Properties: { Period: "14" },
  },
  {
    Id: "sma_indicator",
    Description: "",
    Type: "Indicators",
    Short: "SMA(Period)",
    Full: "Simple Moving Average",
    Backend: {
      Feature: {
        "feature_name": "sma_indicator_Period",
        "feature_type": "sma_indicator",
        "feature_value": "Period"
      },
      Usage: "sma_indicator_Period",
    },
    Properties: { Period: "14" },
  },
  {
    Id: "ema_indicator",
    Description: "",
    Type: "Indicators",
    Short: "EMA(Period)",
    Full: "Exponential Moving Average",
    Backend: {
      Feature: {
        "feature_name": "ema_indicator_Period",
        "feature_type": "ema_indicator",
        "feature_value": "Period"
      },
      Usage: "ema_indicator_Period",
    },
    Properties: { Period: "14" },
  },
  {
    Id: "rsi",
    Description: "",
    Type: "Indicators",
    Short: "RSI(Period)",
    Full: "Relative Strength Index",
    Backend: {
      Feature: {
        "feature_name": "rsi_Period",
        "feature_type": "rsi",
        "feature_value": "Period"
      },
      Usage: "rsi_Period",
    },
    Properties: { Period: "14" },
  },
  {
    Id: "macd",
    Description: "",
    Type: "Indicators",
    Short: "MacdValue MACD(Fast, Slow, Signal, Offset)",
    Full: "Moving Average Convergence Divergence",
    Backend: {
      Feature: {
        "feature_name": "macd_Fast_Slow_Signal_Offset",
        "feature_type": "macd",
        "feature_value": ["Fast", "Slow", "Signal", "Offset"]
      },
      Usage: "MacdValue_macd_Fast_Slow_Signal_Offset",
    },
    Properties: {
      MacdValue: { 1: "f", 2: "h", 3: "s" },
      Fast: "12",
      Slow: "26",
      Signal: "9",
      Offset: "0",
    },
  },
  {
    Id: "supertrend",
    Description: "",
    Type: "Indicators",
    Short: "Supertrend(Length, Multiplier, Offset)",
    Full: "Supertrend",
    Backend: {
      Feature: {
        "feature_name": "supertrend_Length_Multiplier_Offset",
        "feature_type": "supertrend",
        "feature_value": ["Length", "Multiplier", "Offset"]
      },
      Usage: "supertrend_Length_Multiplier_Offset",
    },
    Properties: {
      Length: "7",
      Multiplier: "3",
      Offset: "0",
    },
  },
  {
    Id: "date",
    Description:
      "Number of days to be shifted from current date(0) whole number. Deprecated",
    Type: "Indicators",
    Short: "date(Shifts)",
    Full: "Date",
    Backend: {
      Feature: {
        "feature_name": "date_Shifts",
        "feature_type": "date",
        "feature_value": "Shifts"
      },
      Usage: "date_Shifts",
    },
    Properties: { Shifts: 1 },
  },
  {
    Id: "delta_date",
    Description:
      "Number of days to be shifted from current delta_date(0) whole number",
    Type: "Indicators",
    Short: "delta_date(Shifts)",
    Full: "Delta Date",
    Backend: {
      Feature: {
        "feature_name": "delta_date_Shifts",
        "feature_type": "delta_date",
        "feature_value": "Shifts"
      },
      Usage: "delta_date_Shifts",
    },
    Properties: { Shifts: 1 },
  },
  {
    Id: "time",
    Description: "",
    Type: "Indicators",
    Short: "time(TimeV)",
    Full: "Time.",
    Backend: {
      Feature: {
        "feature_name": "time_TimeV",
        "feature_type": "time",
        "feature_value": "TimeV"
      },
      Usage: "time_TimeV",
    },
    Properties: { TimeV: "09:25:00" },
  },
  {
    Id: "number",
    Description: "",
    Type: "Indicators",
    Short: "Value",
    Full: "Number",
    Backend: {
      Feature: {},
      Usage: "Value",
    },
    Properties: { Value: "100" },
  },
  {
    Id: "shift",
    Description: "",
    Type: "Indicators",
    Short: "Shift(ShiftColumn, Shifts)",
    Full: "Shift",
    Backend: {
      Feature: {
        "feature_name": "shift_ShiftColumn_Shifts",
        "feature_type": "shift",
        "feature_value": ["ShiftColumn", "Shifts"]
      },
      Usage: "shift_ShiftColumn_Shifts",
    },
    Properties: { ShiftColumn: "close", Shifts: 1 },
  },
  {
    Id: "entry",
    Description: "",
    Type: "Indicators",
    Short: "Entry;",
    Full: "Entry",
    Backend: {
      Feature: {},
      Usage: "entry;",
    },
    Properties: {},
  },
  {
    Id: "exit",
    Description: "",
    Type: "Indicators",
    Short: "Exit;",
    Full: "Exit",
    Backend: {
      Feature: {},
      Usage: "exit;",
    },
    Properties: {},
  },
  {
    Id: "hold",
    Description: "",
    Type: "Indicators",
    Short: "Hold[Hold_Key];",
    Full: "Hold Conditions",
    Backend: {
      Feature: {},
      Usage: "hold[\"Hold_Key\"];",
    },
    Properties: { Hold_Key: "DynamicHoldConditions" },
  }
];
const Variables = [
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "time",
    Full: "Current Time",
    Backend: {
      Feature: {},
      Usage: "time",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "Underlying(OHLCV)",
    Full: "Current Underlying OHLCV Data",
    Backend: {
      Feature: {},
      Usage: "OHLCV",
    },
    Properties: {
      OHLCV: { 1: "open", 2: "high", 3: "low", 4: "close", 5: "volume", 6: "open_interest" },
    },
  },
  {
    Id: "options",
    Description: "",
    Type: "Variables",
    Short: "Options(OP_KeyOHLCV)",
    Full: "Current Options OHLCV Data",
    Backend: {
      Feature: {},
      Usage: "OP_KeyOHLCV",
    },
    Properties: {
      OHLCV: {
        1: "open", 2: "high", 3: "low", 4: "close", 5: "volume", 6: "open_interest",
        7: "expiry_date", 8: "strike_price", 9: "right"
      },
      OP_Key: "DynamicOptionContracts",
    },
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "previous day high",
    Full: "Previous Day High",
    Backend: {
      Feature: {},
      Usage: "pre_day_high",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "previous day low",
    Full: "Previous Day Low",
    Backend: {
      Feature: {},
      Usage: "pre_day_low",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "previous day open",
    Full: "Previous Day Open",
    Backend: {
      Feature: {},
      Usage: "pre_day_open",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "previous day close",
    Full: "Previous Day Close",
    Backend: {
      Feature: {},
      Usage: "pre_day_close",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "open price of today",
    Full: "Open Price Of Today",
    Backend: {
      Feature: {},
      Usage: "open_today",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Variables",
    Short: "week day",
    Full: "Week Day",
    Backend: {
      Feature: {},
      Usage: "week_day",
    },
    Properties: {},
  },
];
const MathFunctions = [
  {
    Id: "",
    Description: "",
    Type: "MathFunctions",
    Short: "min",
    Full: "Minimum",
    Backend: {
      Feature: {},
      Usage: "min",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "MathFunctions",
    Short: "max",
    Full: "Maximum",
    Backend: {
      Feature: {},
      Usage: "max",
    },
    Properties: {},
  },
];
const Operators = [
  {
    Id: "",
    Description: "",
    Type: "Operators",
    Short: "+",
    Full: "+",
    Backend: {
      Feature: {},
      Usage: "+",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Operators",
    Short: "-",
    Full: "-",
    Backend: {
      Feature: {},
      Usage: "-",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Operators",
    Short: "*",
    Full: "*",
    Backend: {
      Feature: {},
      Usage: "*",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Operators",
    Short: "/",
    Full: "/",
    Backend: {
      Feature: {},
      Usage: "/",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Operators",
    Short: "%",
    Full: "%",
    Backend: {
      Feature: {},
      Usage: "%",
    },
    Properties: {},
  },
];
const LogicalOperators = [
  {
    Id: "",
    Description: "",
    Type: "LogicalOperators",
    Short: "and",
    Full: "And.",
    Backend: {
      Feature: {},
      Usage: "&",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "LogicalOperators",
    Short: "or",
    Full: "Or.",
    Backend: {
      Feature: {},
      Usage: "|",
    },
    Properties: {},
  },
];
const Comparators = [
  {
    Id: "",
    Description: "",
    Type: "Comparators",
    Short: "lower than",
    Full: "Lower Than.",
    Backend: {
      Feature: {},
      Usage: "<",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Comparators",
    Short: "higher than",
    Full: "Higher Than.",
    Backend: {
      Feature: {},
      Usage: ">",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Comparators",
    Short: "lower than equal to",
    Full: "Lower Than Equal To",
    Backend: {
      Feature: {},
      Usage: "<=",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Comparators",
    Short: "higher than equal to",
    Full: "Higher Than Equal To",
    Backend: {
      Feature: {},
      Usage: ">=",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Comparators",
    Short: "equal to",
    Full: "Equal To.",
    Backend: {
      Feature: {},
      Usage: "==",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Comparators",
    Short: "not equal to",
    Full: "Not Equal To",
    Backend: {
      Feature: {},
      Usage: "!=",
    },
    Properties: {},
  },
];
const Brackets = [
  {
    Id: "",
    Description: "",
    Type: "Brackets",
    Short: "(",
    Full: "(",
    Backend: {
      Feature: {},
      Usage: "(",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Brackets",
    Short: ")",
    Full: ")",
    Backend: {
      Feature: {},
      Usage: ")",
    },
    Properties: {},
  },
];
const Others = [
  {
    Id: "",
    Description: "",
    Type: "Others",
    Short: "crosses above",
    Full: "Crosses Above",
    Backend: {
      Feature: {},
      Usage: "",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Others",
    Short: "crosses below",
    Full: "Crosses Below",
    Backend: {
      Feature: {},
      Usage: "",
    },
    Properties: {},
  },
  {
    Id: "",
    Description: "",
    Type: "Others",
    Short: "up by Value Type from",
    Full: "Up By",
    Backend: {
      Feature: {},
      Usage: "",
    },
    Properties: {
      Type: ["%", "abs"],
      Value: 15,
    },
  },
  {
    Id: "",
    Description: "",
    Type: "Others",
    Short: "down by Value Type from",
    Full: "Down By",
    Backend: {
      Feature: {},
      Usage: "",
    },
    Properties: {
      Type: ["%", "abs"],
      Value: 15,
    },
  },
];

export const conditionCollectionMain = {
  All: [...Indicators, ...Variables, ...MathFunctions, ...Brackets, ...Operators,
  ...LogicalOperators,
  ...Comparators,
  ...Others,
  ],
  Indicators: Indicators,
  Variables: Variables,
  MathFunctions: MathFunctions,
  Brackets: Brackets,
  Operators: Operators,
  LogicalOperators: LogicalOperators,
  Comparators: Comparators,
  Others: Others,

};
export const conditionCollectionScnd = {
  All: [
    ...Operators,
    ...LogicalOperators,
    ...Comparators,
    ...Others,
    ...Brackets,
  ],
  Operators: Operators,
  LogicalOperators: LogicalOperators,
  Comparators: Comparators,
  Others: Others,
  Brackets: Brackets,
};
