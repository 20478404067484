import React, { } from "react";
import ConditionBuilder from "./ConditionBuilder";
import { TextInput, Dropdown } from "./FormCompos";
import "../../styles/ConditionBuilder.css"
const PExitCondition = ({ OcLen, holdCondLen, label = "", contracts, PExitCondData, setPExitCondData }) => {

    const contractOptions = Array.isArray(contracts)
        ? ["none", "under", ...contracts.map((_, index) => `op${index + 1}_`)] // Add default option
        : ["none", "under"];

    const handleChange = (field, value) => {
        setPExitCondData({
            ...PExitCondData,
            partial_exit_price_change: {
                ...PExitCondData.partial_exit_price_change,
                [field]: value,
            },
        });
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "left", gap: "1rem" }} >

            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>

                <Dropdown
                    label="OnWhat"
                    options={contractOptions}
                    value={PExitCondData.partial_exit_price_change.name}
                    onChange={(value) => handleChange("name", value)}
                />

                <TextInput
                    label="Stoploss"
                    value={PExitCondData.partial_exit_price_change.stoploss}
                    onChange={(value) => handleChange("stoploss", value)}
                    placeholder=""
                />

                <TextInput
                    label="Target"
                    value={PExitCondData.partial_exit_price_change.target}
                    onChange={(value) => handleChange("target", value)}
                    placeholder=""
                />

                <ConditionBuilder
                    label={label}
                    OcLen={OcLen}
                    holdCondLen={holdCondLen}
                    localConditions={PExitCondData.partial_ext_cond}
                    setLocalConditions={(newItems) => setPExitCondData({ ...PExitCondData, partial_ext_cond: newItems })}
                />

            </div>

        </div>
    );
};

export default PExitCondition;
