// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TemplateContainer {
    background-color: var(--primaryL);
    width: 90vw;

    padding: 1rem;

    border-radius: 8px;
}

.TemplateCard {
    background-color: rgb(255, 255, 255);
    padding: .5rem 1rem;
    box-shadow: 4px 4px 4px #C6D8F3;
    border-radius: 10px;
}

.InstrumentCard {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.button-section button {
    flex: 1 1;
    background-color: var(--Pblue);
    color: var(--Pcontrast);
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button-section button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.button-section button:not([disabled]):hover {
    background-color: var(--PblueHover);
    color: var(--Pcontrast);
}

.toast {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/styles/Template.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,WAAW;;IAEX,aAAa;;IAEb,kBAAkB;AACtB;;AAEA;IACI,oCAAoC;IACpC,mBAAmB;IACnB,+BAA+B;IAC/B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,SAAO;IACP,8BAA8B;IAC9B,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,mCAAmC;IACnC,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,SAAS;IACT,2BAA2B;IAC3B,oCAAoC;IACpC,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,UAAU;IACV,oCAAoC;IACpC,aAAa;AACjB","sourcesContent":[".TemplateContainer {\r\n    background-color: var(--primaryL);\r\n    width: 90vw;\r\n\r\n    padding: 1rem;\r\n\r\n    border-radius: 8px;\r\n}\r\n\r\n.TemplateCard {\r\n    background-color: rgb(255, 255, 255);\r\n    padding: .5rem 1rem;\r\n    box-shadow: 4px 4px 4px #C6D8F3;\r\n    border-radius: 10px;\r\n}\r\n\r\n.InstrumentCard {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    gap: 1rem;\r\n}\r\n\r\n.button-section button {\r\n    flex: 1;\r\n    background-color: var(--Pblue);\r\n    color: var(--Pcontrast);\r\n    border: none;\r\n    padding: 10px 20px;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s ease;\r\n}\r\n\r\n.button-section button:disabled {\r\n    background-color: #ccc;\r\n    cursor: not-allowed;\r\n}\r\n\r\n.button-section button:not([disabled]):hover {\r\n    background-color: var(--PblueHover);\r\n    color: var(--Pcontrast);\r\n}\r\n\r\n.toast {\r\n    position: fixed;\r\n    bottom: 20px;\r\n    left: 50%;\r\n    transform: translateX(-50%);\r\n    background-color: rgba(0, 0, 0, 0.8);\r\n    color: white;\r\n    padding: 10px 15px;\r\n    border-radius: 5px;\r\n    font-size: 14px;\r\n    opacity: 1;\r\n    transition: opacity 0.5s ease-in-out;\r\n    z-index: 1000;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
