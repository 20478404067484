import React, { useState, useEffect } from "react";
import { api7777 } from "../../utils/axiosConfig";
import "../../styles/IndicatorDropdown.css";

const InstrumentDropdown = (({ label = "djdjj", selectedInstrument, setSelectedInstrument }) => {

    const [searchTerm, setSearchTerm] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [equityListerror, setError] = useState("");
    const [equityList, seEquityList] = useState([]);

    const fetchEquityList = async () => {
        try {
            const response = await api7777.get("/get_equity_list/", {});
            if (response.status === 500) {
                setError("Error fetching list");
            } else {
                const equityLi = response.data.Equity || [];
                const updatedEquityList = ["NIFTY", "NIFTY_fut", "BANKNIFTY", "BANKNIFTY_fut", ...equityLi.flatMap(item => [item, `${item}_fut`])];
                seEquityList(updatedEquityList);
                setError("");
            }
        } catch (err) {
            seEquityList([]);
            setError(JSON.stringify(err));
        }
    };

    useEffect(() => {
        fetchEquityList();
    }, []);

    const allInstruments = equityList;

    const handleSearch = (e) => {
        setSearchTerm(e.target.value.toUpperCase());
    };

    const handleSelect = (instru) => {
        setSelectedInstrument(instru);
        setIsDropdownOpen(false);
    }

    const handleToggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);

    };

    const filteredInstrument = searchTerm.trim() === ""
        ? allInstruments
        : allInstruments.filter((instrum) =>
            instrum.toUpperCase().includes(searchTerm.trim())
        );

    return (
        <div style={{ marginBottom: "1rem", position: "relative" }}>
            <label style={{ display: "block", fontSize: "0.7rem", fontWeight: "300", marginBottom: "0.1rem" }}>
                {label}
            </label>
            <div className="dropdown">

                <button type="button" className="dropdown-button1" onClick={handleToggleDropdown}  >
                    {selectedInstrument ? selectedInstrument : <span className="add-text">+ ADD</span>}
                    <span className="dropdown-arrow"> ⮟ </span>
                </button>

                {isDropdownOpen && (
                    <div className="dropdown-content">
                        <input
                            type="text"
                            placeholder="Search ..."
                            value={searchTerm}
                            onChange={handleSearch}
                            className="search-input"
                        />
                        <div className="grid-container">
                            {filteredInstrument.length > 0 ? (
                                filteredInstrument.map((instrum) => (
                                    <div
                                        key={instrum}
                                        className="grid-item"
                                        onClick={() => handleSelect(instrum)}
                                    >
                                        {instrum}
                                    </div>
                                ))
                            ) : (
                                <div className="no-results">No Results Found</div>
                            )}
                        </div>
                    </div>
                )}
                {equityListerror && <p className="error-message">{equityListerror}</p>}
            </div>
        </div>
    );

});

export default InstrumentDropdown;