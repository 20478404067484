import { configureStore } from "@reduxjs/toolkit";
import modelsReducer from "./modelsSlice";
import featuresReducer from "./featuresSlice";
import emailReducer from "./emailSlice";


const store = configureStore({
  reducer: {
    models: modelsReducer,
    features: featuresReducer,
    email: emailReducer,
  },
});

export default store;