import React from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import IndicatorDropdown from "./IndicatorDropdown";
import "../../styles/ConditionBuilder.css";

const ConditionBuilder = ({ label = "", OcLen, holdCondLen, localConditions, setLocalConditions }) => {

    const handleUpdateDropdown = (index, updatedDropdown) => {
        const updatedConditions = [...localConditions];
        updatedConditions[index] = { ...updatedConditions[index], element: updatedDropdown };

        if (updatedConditions.length === 0 || updatedConditions[updatedConditions.length - 1].element !== null) {
            updatedConditions.push({ id: Date.now().toString(), element: null });
        }

        setLocalConditions(updatedConditions);
    };

    const handleRemoveDropdown = (index) => {
        if (localConditions.length > 1) {
            const updatedConditions = localConditions.filter((_, i) => i !== index);
            setLocalConditions(updatedConditions);
        }
    };

    const handleReset = () => {
        if (localConditions.length === 1 && !localConditions[0].element) {
            return;
        }
        setLocalConditions([{ id: Date.now().toString(), element: null }]);
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const updatedConditions = [...localConditions];
        const [movedItem] = updatedConditions.splice(result.source.index, 1);
        updatedConditions.splice(result.destination.index, 0, movedItem);

        setLocalConditions(updatedConditions);
    };

    return (
        <div className="entry-condition-builder">
            <div className="header-container">
                <label className="condition-label">{label}</label>
                <button className="head-button" onClick={handleReset}>Reset</button>
            </div>

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="condition-list" direction="horizontal">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} className="condition-list" >
                            {localConditions.map((condition, index) => (
                                <Draggable key={index.toString()} draggableId={index.toString()} index={index} >
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="condition-item" style={{ ...provided.draggableProps.style, }}>
                                            <IndicatorDropdown
                                                holdCondLen={holdCondLen}
                                                OcLen={OcLen}
                                                savedIndicator={condition.element}
                                                onUpdate={(updatedDropdown) =>
                                                    handleUpdateDropdown(index, updatedDropdown)
                                                }
                                            />
                                            {index !== localConditions.length - 1 && (
                                                <button type="button" className="delete-condition" onClick={() => handleRemoveDropdown(index)} >
                                                    ✕ </button>
                                            )}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default ConditionBuilder;