import React, { useEffect, useState } from "react";
import api from '../utils/axiosConfig';
import '../styles/Backtest.css';
import { useSelector, useDispatch } from "react-redux";
import { setModelName } from "../redux/modelsSlice";
import { useLocation } from "react-router-dom";
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

const Backtest = () => {
  const model_name = useSelector((state) => state.models.model_name);
  const dispatch = useDispatch();
  const email = useSelector((state) => state.email.value);
  const [date, setDate] = useState("2017-01-01");
  const location = useLocation();
  const [modelDetails, setModelDetails] = useState(location.state?.templateJSON || "");
  const [marketDataRecentNRows, setMarketDataRecentNRows] = useState(5000);
  const [generatedTaskId, setGeneratedTaskId] = useState("");
  const [error, setError] = useState("");
  const [downloadStatus, setDownloadStatus] = useState("");
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [comment, setComment] = useState(selectedTask?.comment || "");
  const [loading, setLoading] = useState(true);
  const fetchTasks = async () => {
    const backtestingRef = collection(db, "backtesting_celery_tasks"); // Reference collection
    const q = query(backtestingRef, where("Email", "==", email)); // Filter by email

    try {
      const querySnapshot = await getDocs(q);
      const fetchedTasks = querySnapshot.docs
        .map((doc) => {
          const taskData = doc.data();
          return { id: doc.id, ...taskData };
        })
        .sort((a, b) => {
          const dateA = new Date(`${a.date}T${a.time}`);
          const dateB = new Date(`${b.date}T${b.time}`);
          return dateB - dateA;
        });
      setTasks(fetchedTasks);
    } catch (err) {
      setError(JSON.stringify(err));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTasks();
  }, [generatedTaskId]);

  useEffect(() => {
    if (selectedTask) {
      setComment(selectedTask.comment || ""); // Ensure it updates properly
    }
  }, [selectedTask]);

  const submitBacktest = async (e) => {
    e.preventDefault();
    setError("");
    setGeneratedTaskId("");
    try {
      const model_detail = JSON.parse(modelDetails)
      model_detail['Email'] = email
      model_detail['modelName'] = model_name
      model_detail['date'] = date
      model_detail['market_data_recent_n_rows'] = marketDataRecentNRows
      const response = await api.post('/backtesting', model_detail);
      setGeneratedTaskId(response.data.task_id);

      setError(response.data.error);
    } catch (err) {
      setError(JSON.stringify(err));
    }
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const saveComment = async () => {
    if (!selectedTask || !selectedTask.id) return;
    const taskRef = doc(db, "backtesting_celery_tasks", selectedTask.id);

    try {
      await updateDoc(taskRef, { comment });
      fetchTasks();
      setSelectedTask((prev) => ({ ...prev, comment }));
    } catch (err) {
      setError("Failed to save comment");
    }
  };

  const handleDownload = async (file_details) => {
    setError("");
    try {
      setDownloadStatus("Preparing download...");
      const responseFile = await api.post(
        "/results_file",
        {
          date: file_details.date,
          name: file_details.name,
        },
        {
          responseType: "blob", // Ensures the response is treated as a binary file
        }
      );

      if (responseFile.status === 200) {
        setError("");
        setDownloadStatus("Downloading...");

        const blob = new Blob([responseFile.data], { type: responseFile.headers["content-type"] });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file_details.name}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloadStatus("Downloaded successfully");
      } else {
        setDownloadStatus("");
        setError("Error getting the file");
      }

    } catch (err) {
      setError(JSON.stringify(err));
    }
  };
  const handleCheckboxChange = (task) => {
    setSelectedTasks((prevSelected) => {
      if (prevSelected.includes(task)) {
        return prevSelected.filter((t) => t !== task);
      } else {
        return [...prevSelected, task];
      }
    });
  };

  const clearSelections = () => {
    setSelectedTasks([]);
    setError("");
    setDownloadStatus("");
  };

  const submitSelections = async () => {
    setLoading(true);
    setError("");
    try {
      setDownloadStatus("Preparing download...");

      const responseFile = await api.post(
        "/combined_results_file",
        { file_name: selectedTasks.map((task) => task.file_details) },
        { responseType: "blob" }
      );
      ;
      if (responseFile.status === 200) {
        setError("");
        setDownloadStatus("Downloading...");
        const blob = new Blob([responseFile.data], { type: responseFile.headers["content-type"] });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "combined_results_file.xlsx"); // Default filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloadStatus("Downloaded successfully");
      } else {
        setDownloadStatus("");
        setError("Error getting the file");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setDownloadStatus("");
      setError("Error getting the file");
    }
  };

  return (
    <div className="container">
      <div className="card">
        <h2>Backtesting</h2>
        <form >
          <div className="input-group">
            <label htmlFor="model_name">Model Name:</label>
            <input
              type="text"
              id="model_name"
              value={model_name}
              onChange={(e) => dispatch(setModelName(e.target.value))}
              placeholder="Enter Model Name"
            /></div>

          <div className="input-group">
            <label htmlFor="date">Date:</label>
            <input
              type="text"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              placeholder="Enter Date"
            /></div>
          <label htmlFor="modelDetails">Model Details:</label>
          <textarea style={{ height: "55vh" }}
            id="modelDetails"
            value={modelDetails}
            onChange={(e) => setModelDetails(e.target.value)}
            placeholder="Enter Model Details"
          />

          <div className="input-group">
            <label htmlFor="marketDataRecentNRows">Market Data Recent N Rows:</label>
            <input
              type="text"
              id="marketDataRecentNRows"
              value={marketDataRecentNRows}
              onChange={(e) => setMarketDataRecentNRows(e.target.value)}
              placeholder="Enter Market Data Recent N Rows"
            /></div>
          <div className="button-section">
            <button onClick={submitBacktest}>Submit for Backtest</button>
          </div>
        </form>
        {generatedTaskId && (
          <p className="success-message">Generated Task ID: {generatedTaskId}</p>
        )}
        {downloadStatus && <p>{downloadStatus}</p>}
        {error && <p className="error-message">{error}</p>}
      </div>

      <div className="card">
        <h2>Model Names</h2>
        <div className="button-section">
          <button onClick={clearSelections} disabled={loading}>  {loading ? "Loading..." : "Clear Selections"}</button>
          <button onClick={submitSelections} disabled={loading}>  {loading ? "Loading..." : "Submit Combined"}</button>
        </div>

        {loading ? (
          <p>Loading...</p>
        ) : tasks.length > 0 ? (
          <div className="task-list-wrapper">
            {tasks.map((task, index) => (
              <div key={index} className="task-item">
                <div className="checkbox-placeholder">
                  {task.file_details?.name && (
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(task)}
                      checked={selectedTasks.includes(task)}
                    />
                  )}</div>
                <ul className="task-list">
                  <li>
                    <button onClick={() => setSelectedTask(task)}
                      className={`link-button ${selectedTask === task ? "selected" : ""}`}
                    >
                      {task.modelName}  {task.comment}
                    </button>

                  </li>
                </ul>
              </div>
            ))}
          </div>
        ) : (
          <p>No models found for this email.</p>
        )}
      </div>

      <div className="card">
        <h2>Task Details</h2>
        {selectedTask && (
          <div className="task-details">
            <textarea
              value={comment}
              onChange={handleCommentChange}
              placeholder="comment"
            />
            <button onClick={saveComment}>Save Comment</button>
            <pre>{JSON.stringify({
              // id: selectedTask.id,
              // comment: selectedTask.comment,
              file_details: selectedTask.file_details,
              error: selectedTask.error,
              status: selectedTask.status,
              date: selectedTask.date,
              time: selectedTask.time,
              modelName: selectedTask.modelName,
              Email: selectedTask.Email
            }, null, 2)}</pre>

            {selectedTask.file_details?.name && (<div>
              <button onClick={() => handleDownload(selectedTask.file_details)} className="download-button">
                Download File
              </button>
            </div>
            )}
            <pre>{JSON.stringify(JSON.parse(selectedTask.model_details), null, 2)}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default Backtest;