import React from "react";
import { FaTrash } from "react-icons/fa";

// TextInput Component
export const TextInput = ({ label, value, onChange, placeholder = "" }) => (
  <div style={{ marginBottom: "1rem" }}>
    <label style={{ display: "block", fontSize: "0.7rem", fontWeight: "300", marginBottom: "0.1rem" }}>
      {label}
    </label>
    <input
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      style={{
        width: "auto",
        maxWidth: "6rem",
        fontWeight: "500",
        padding: "0.5rem",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
    />
  </div>
);

// Dropdown Component
export const Dropdown = ({ label = "", options, value, onChange }) => (
  <div style={{ marginBottom: ".1rem" }}>
    <label style={{ display: "block", fontSize: "0.7rem", fontWeight: "300", marginBottom: "0.1rem" }}>
      {label}
    </label>
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      style={{
        width: "auto",
        padding: "0.5rem",
        fontWeight: "500",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
    >

      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);

// IconButton Component
export const IconButton = ({ icon, label, onClick }) => {
  const renderIcon = () => {
    if (icon === "-") return <FaTrash />;
    return icon;
  };

  return (
    <button
      onClick={onClick}
      aria-label={label}
      style={{
        color: "#0000FF",
        margin: "0.9rem 0rem",
        padding: "0.5rem 1rem",
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        fontWeight: "600",
        cursor: "pointer",
        border: "1px solid #ccc",
        borderRadius: "4px",
        backgroundColor: "#f5f5f5",
      }}
    >
      {renderIcon()} {label}
    </button>
  );
}

// Label Component
export const Label = ({ text }) => (
  <label
    style={{
      color: "Blue",
      fontWeight: "700",
      marginTop: "1.2rem",

    }}
  >
    {text}
  </label>
);