import React, { } from "react";
import ConditionBuilder from "./ConditionBuilder";
import { Dropdown } from "./FormCompos";

const EntryCondition = ({ OcLen, holdCondLen, EntryCondData, setentryCondData }) => {

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "left", gap: "1rem" }} >
      <h3 style={{ align: "left", fontSize: "1rem", marginBottom: "0rem" }}>Entry Condition</h3>

      <ConditionBuilder
        label="Entry Condition"
        OcLen={OcLen}
        holdCondLen={holdCondLen}
        localConditions={EntryCondData.ent_cond}
        setLocalConditions={(newItems) => setentryCondData({ ...EntryCondData, ent_cond: newItems })}
      />

      <Dropdown
        label="Exit Entry condition combined"
        options={["none", "and", "or"]}
        value={EntryCondData.ext_ent_cond_comb}
        onChange={(value) => setentryCondData({ ...EntryCondData, ext_ent_cond_comb: value })}
      />

      <ConditionBuilder
        holdCondLen={holdCondLen}
        OcLen={OcLen}
        label="Entry Exit Condition"
        localConditions={EntryCondData.ext_ent_cond}
        setLocalConditions={(newItems) => setentryCondData({ ...EntryCondData, ext_ent_cond: newItems })}
      />

    </div>
  );
};

export default EntryCondition;